import React, { useEffect, useState } from 'react';
import { Asset, AssetListScope } from '@cognite/sdk';
import { Cascader, Spin } from 'antd';
import { CascaderOptionType } from 'antd/lib/cascader';
import { loadAllSolarSiteFromCDFByScope, loadAllAssetsByParentIds } from '../../../utils/Asset/SolarSiteAsset';

export interface SiteCascaderSelect {
  businessClassification: Asset,
  firstCategory: Asset,
  secondCategory: Asset,
  site: Asset,
}

interface SiteCascaderProps {
  onChange: (selectedValues?: SiteCascaderSelect) => void;
  changeOnSelect?: boolean,
}

/**
 * サイト選択Cascader
 */
const SiteCascader: React.FC<SiteCascaderProps> = (props) => {
  const [siteOptions, setSiteOptions] = useState<CascaderOptionType[] | undefined>(undefined);

  useEffect(() => {
    let canceled = false;
    (async () => {
      // metadata.assetTypeがsdfassetを持つデータは必ず1件しか存在しないためlimitは不要
      const scope: AssetListScope = {
        filter: {
          metadata: { assetType: 'sdfasset' },
        },
      };
      const [sdfAsset] = await loadAllSolarSiteFromCDFByScope(scope);

      // 事業
      const loadBusinessClassifications = await loadAllAssetsByParentIds([sdfAsset.id]);
      // 第１カテゴリー
      const loadBusinessClassificationIds = loadBusinessClassifications.map(({ id }) => id);
      const loadFirstCategories = await loadAllAssetsByParentIds(loadBusinessClassificationIds);
      // 第２カテゴリー
      const loadFirstCategoryIds = loadFirstCategories.map(({ id }) => id);
      const loadSecondCategories = await loadAllAssetsByParentIds(loadFirstCategoryIds);
      // サイト
      const loadSecondCategoryIds = loadSecondCategories.map(({ id }) => id);
      const loadSites = await loadAllAssetsByParentIds(loadSecondCategoryIds);

      // Asset.nameの昇順でソート
      const sortByName = (a: Asset, b: Asset) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      };
      const loadOptions: CascaderOptionType[] = loadBusinessClassifications
        .sort(sortByName)
        .map((businessClassification): CascaderOptionType => {
          // 事業
          const { id: businessClassificationId, name: businessClassificationName } = businessClassification;

          // 第１カテゴリー
          const firstCategories = loadFirstCategories
            .filter((firstCategory) => firstCategory.parentId === businessClassificationId)
            .sort(sortByName)
            .map((firstCategory): CascaderOptionType => {
              const { id: firstCategoryId, name: firstCategoryName } = firstCategory;

              // 第２カテゴリー
              const secondCategories = loadSecondCategories
                .filter((secondCategory) => secondCategory.parentId === firstCategoryId)
                .sort(sortByName)
                .map((secondCategory): CascaderOptionType => {
                  const { id: secondCategoryId, name: secondCategoryName } = secondCategory;

                  // サイト
                  const sites = loadSites
                    .filter((site) => site.parentId === secondCategoryId)
                    .sort(sortByName)
                    .map((site): CascaderOptionType => ({ label: site.name, value: site.id.toString(), asset: site }));

                  return {
                    label: secondCategoryName, value: secondCategoryId.toString(), children: sites, asset: secondCategory,
                  };
                });

              return {
                label: firstCategoryName, value: firstCategoryId.toString(), children: secondCategories, asset: firstCategory,
              };
            });

          return {
            label: businessClassificationName, value: businessClassificationId.toString(), children: firstCategories, asset: businessClassification,
          };
        });
      if (!canceled) {
        setSiteOptions(loadOptions);
      }
    })();

    return () => { canceled = true; };
  }, []);

  /**
   * カスケードチェンジイベントハンドラ
   * @param {string[]} _ 選択した要素のvalueリスト
   * @param {CascaderOptionType[]} selectedOptions 選択した要素のCascaderOptionTypeリスト
   */
  const handleChangeCascader = (_: string[], selectedOptions?: CascaderOptionType[]): void => {
    const { onChange } = props;
    if (!selectedOptions || selectedOptions.length === 0) {
      onChange(undefined);
      return;
    }

    const [
      businessClassification, firstCategory, secondCategory, site,
    ] = selectedOptions;
    onChange({
      businessClassification: businessClassification.asset,
      firstCategory: firstCategory ? firstCategory.asset : undefined,
      secondCategory: secondCategory ? secondCategory.asset : undefined,
      site: site ? site.asset : undefined,
    });
  };
  const { changeOnSelect } = props;
  return (
    <Spin spinning={!siteOptions}>
      <Cascader
        options={siteOptions}
        expandTrigger="hover"
        onChange={handleChangeCascader}
        style={{ width: '100%' }}
        changeOnSelect={changeOnSelect}
      />
    </Spin>
  );
};

export default SiteCascader;
