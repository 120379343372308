import React, { useEffect, useState } from 'react';

import {
  Spin,
  Tree,
  Tooltip,
  message,
  Layout,
  Typography,
  Divider,
} from 'antd';
import { AntTreeNodeExpandedEvent, AntTreeNodeSelectedEvent } from 'antd/lib/tree';

import OMEquipment, { FilterAssetMode } from '../../../../utils/Asset/OMEquipment';
import TreeNode from '../../../../utils/common/TreeNode';
import { ERROR_LOAD_FACILITY_ASSET_TREE, ERROR_NO_AUTH_MESSAGE } from '../../../../utils/messages';

import './OMEquipmentTree.css';

const { Sider } = Layout;
const { Title } = Typography;

const MANAGED_FACILITY_POSITION_KEY = '0-0';
const DEFAULT_TREE_WIDTH = 300;
const FIRST_OPENED_TREE_WIDTH = 340;
const ADD_TREE_WIDTH = 18;
const CHILD_FACILITY_LENGTH = 2;
const NAME_LENGTH_LIMIT = 15;

const OMEquipmentTree: React.FC<{
  parentAssetsId?: number,
  height?: number,
  title: string,
  onSelect: (asset?: OMEquipment) => void
  collapsed?: boolean,
  onCollapse?: (value: boolean) => void
  isExpandedRoot?: boolean,
  isReadDisabled?: boolean,
  filterMode?: FilterAssetMode,
}> = (props) => {
  /*
   * 変数/定数定義
   */
  const [loading, setLoading] = useState<boolean>(true);
  const [rootTreeNode, setRootTreeNode] = useState<
    TreeNode<OMEquipment>
  >();
  const [treeWidth, setTreeWidth] = useState<number>(DEFAULT_TREE_WIDTH);
  const [treePositionList, setTreePositionList] = useState<{
    positionKey: string; length: number; expanded: boolean;
  }[]>([]);
  const [siderHeight, setSiderHeight] = useState<number>(window.innerHeight);
  const [siderWidth, setSiderWidth] = useState<number>(window.innerWidth);
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<string[]>([]);

  const {
    parentAssetsId,
    height,
    title,
    collapsed,
    isExpandedRoot,
    isReadDisabled,
    filterMode,
  } = props;

  /**
   * Siderサイズ変更
   */
  const changeSiderSize = (): void => {
    const { innerHeight, innerWidth } = window;
    setSiderHeight(innerHeight);
    setSiderWidth(innerWidth);
  };

  /*
   * イベントハンドラ
   */
  useEffect(() => {
    window.addEventListener('resize', changeSiderSize);
    return () => window.removeEventListener('resize', changeSiderSize);
  }, []);

  useEffect(() => {
    if (isExpandedRoot) {
      setTreeWidth(FIRST_OPENED_TREE_WIDTH);
    }
  }, [isExpandedRoot]);

  useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      if (!parentAssetsId) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        let loadRootTreeNode: TreeNode<OMEquipment> | undefined;

        let loadRootId: number | undefined;
        try {
          const site = await OMEquipment.loadOneByIdFromCDF(parentAssetsId);

          if (!site) {
            throw new Error();
          }

          loadRootTreeNode = await site.loadOMEquipmentTreeFromCDF(filterMode);
          loadRootId = site.id;
        } catch (exception) {
          message.error(ERROR_LOAD_FACILITY_ASSET_TREE);
        }

        if (!canceled) {
          if (isExpandedRoot && loadRootId) {
            setDefaultExpandedKeys([loadRootId.toString()]);
          }
          setRootTreeNode(loadRootTreeNode);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [isExpandedRoot, filterMode, loading, parentAssetsId],
  );

  /**
   * 開閉情報変更
   * @param {string} positionKey 対象のTreeNodeの階層情報
   * @param {boolean} flag 開閉フラグ
   */
  const changeExpanded = (positionKey: string, flag: boolean): void => {
    treePositionList
      .filter((treePosition) => treePosition.positionKey === positionKey)
      // eslint-disable-next-line no-param-reassign
      .forEach((treePosition) => { treePosition.expanded = flag; });
  };

  /**
   * 縮小しているTreeNodeの階層情報リスト取得
   * @returns {string[]} 階層情報リスト
   */
  const getNotExpandedKeys = (): string[] => treePositionList
    .filter((treePosition) => !treePosition.expanded)
    .map((treePosition) => treePosition.positionKey);

  /**
   * 展開しているTreeNode情報リスト取得
   * @param {string[]} notExpandedKeys 縮小しているTreeNodeの階層情報リスト
   * @returns {{ positionKey: string; length: number; expanded: boolean; }[]} TreeNode情報リスト
   */
  const getExpandedTreeNodeList = (notExpandedKeys: string[]): {
    positionKey: string; length: number; expanded: boolean;
  }[] => treePositionList.filter((treePosition) => {
    const { expanded } = treePosition;
    const isNotExpandedChildren = !notExpandedKeys.some((notExpandedKey) => {
      const currentPositionKey = treePosition.positionKey;
      return currentPositionKey.startsWith(notExpandedKey);
    });

    return expanded && isNotExpandedChildren;
  });

  /**
   * Tree幅取得
   * @returns {number} Tree幅
   */
  const getTreeWidth = (): number => {
    const notExpandedKeys = getNotExpandedKeys();
    const expandedTreeNodeList = getExpandedTreeNodeList(notExpandedKeys);

    // 展開されているTreeNodeのうち、縮小されているTreeNode配下でないものの中から最大値を取得
    const maxLength = Math.max(
      ...expandedTreeNodeList
        .map((treePosition) => treePosition.length),
    );

    if (maxLength === CHILD_FACILITY_LENGTH) {
      return FIRST_OPENED_TREE_WIDTH;
    }
    return FIRST_OPENED_TREE_WIDTH + (maxLength - 2) * ADD_TREE_WIDTH;
  };

  /**
   * TreeNode展開
   * @param {string} positionKey 対象のTreeNodeの階層情報
   * @param {number} currentTreeLength 対象のTreeNodeの階層の深さ
   * @param {number} treePositionIndex 対象のTreeNodeのindex
   */
  const openTreeNode = (
    positionKey: string,
    currentTreeLength: number,
    treePositionIndex: number,
  ): void => {
    changeExpanded(positionKey, true);

    if (treePositionList.length === 0) {
      // 初回管理設備
      setTreePositionList([{
        positionKey,
        length: currentTreeLength,
        expanded: true,
      }]);
      setTreeWidth(FIRST_OPENED_TREE_WIDTH);
      return;
    }

    // 子設備以降
    if (treePositionIndex < 0) {
      // 未展開子設備
      treePositionList.push({
        positionKey,
        length: currentTreeLength,
        expanded: true,
      });
    }

    setTreePositionList(treePositionList);

    const currentTreeWidth = getTreeWidth();
    if (currentTreeWidth > treeWidth) setTreeWidth(currentTreeWidth);
  };

  /**
   * TreeNode縮小
   * @param {string} positionKey 対象のTreeNodeの階層情報
   */
  const closeTreeNode = (positionKey: string): void => {
    changeExpanded(positionKey, false);
    setTreePositionList(treePositionList);

    if (positionKey === MANAGED_FACILITY_POSITION_KEY) {
      // 管理設備
      setTreeWidth(DEFAULT_TREE_WIDTH);
      return;
    }

    const currentTreeWidth = getTreeWidth();
    if (currentTreeWidth < treeWidth) setTreeWidth(currentTreeWidth);
  };

  /**
   * ツリー開閉時のイベントハンドラ
   * @param {string[]} _
   * @param {AntTreeNodeExpandedEvent} data TreeNode情報
   */
  const handleExpand = (
    _: string[],
    data: AntTreeNodeExpandedEvent,
  ): void => {
    const currentTreePosition = data.node.props.pos;
    const currentTreeLength = currentTreePosition.split('-').length;
    const treePositionIndex = treePositionList.findIndex(
      (position) => position.positionKey === currentTreePosition,
    );
    if (data.expanded) {
      openTreeNode(currentTreePosition, currentTreeLength, treePositionIndex);
    } else {
      closeTreeNode(currentTreePosition);
    }
  };

  /**
   * ツリー選択時のイベントハンドラ
   * @param {string[]} selectedKeys 選択したTreeNodeのkey
   * @param {AntTreeNodeSelectedEvent} info TreeNodeの選択イベント情報
   */
  const handleSelect = (selectedKeys: string[], info: AntTreeNodeSelectedEvent): void => {
    if (info.selected) {
      const key = String(selectedKeys[0]);
      const facilityNode = rootTreeNode?.findNodeByKey(key);
      if (!facilityNode) {
        return;
      }
      props.onSelect(facilityNode.data);
    }
    if (!collapsed && props.onCollapse) props.onCollapse(true);
  };

  /*
   * メソッド
   */

  /**
   * ファイル名描画
   * @param {OMEquipment} facility 設備
   * @return {JSX.Element} ファイル名
   */
  const renderFacilityName = (facility: OMEquipment): JSX.Element => {
    const className = isReadDisabled ? 'name disable' : 'name';
    const tooltipTitle = isReadDisabled ? ERROR_NO_AUTH_MESSAGE : facility.name;
    return (
      <div className={className}>
        <Tooltip title={tooltipTitle} placement="bottomLeft">
          {facility.name.length > NAME_LENGTH_LIMIT ? `${facility.name.substring(0, NAME_LENGTH_LIMIT)}...` : facility.name}
        </Tooltip>
      </div>
    );
  };

  /*
   * 画面描画
   */

  /**
   * TreeNodeのレンダリング
   * @param {TreeNode<OMEquipment>[]} treeNodes Tree用treeNodeリスト
   * @returns {JSX.Element[]} Tree用TreeNode
   */
  const renderTreeNodes = (
    treeNodes: TreeNode<OMEquipment>[],
  ): JSX.Element[] => (
    treeNodes.map((treeNode) => {
      const facility = treeNode.data;
      const floorHasAlert = facility.assetLevel === 'floor' && facility.hasAlert;
      const isNodeDisabled = ['pcs', 'feeder'].includes(facility.assetLevel);
      const titleStyle = floorHasAlert ? { color: 'red' } : { color: 'black' };
      const facilityTitle = (
        <div className="om-dashboard-common-facility-tree-node">
          {renderFacilityName(facility)}
        </div>
      );

      return treeNode.isLeaf
        ? (
          <Tree.TreeNode
            key={`${facility.id}`}
            title={<span style={titleStyle} id={`${facility.id}`}>{facilityTitle}</span>}
            data={treeNode}
            disabled={isNodeDisabled}
          />
        )
        : (
          <Tree.TreeNode
            key={`${facility.id}`}
            title={<span style={titleStyle} id={`${facility.id}`}>{facilityTitle}</span>}
            data={treeNode}
            disabled={isNodeDisabled}
          >
            {renderTreeNodes(treeNode.children)}
          </Tree.TreeNode>
        );
    })
  );

  return (
    <div>
      <Sider
        collapsible
        collapsed={collapsed}
        collapsedWidth={DEFAULT_TREE_WIDTH}
        trigger={null}
        width={siderWidth - 250}
      >
        <Spin spinning={loading} style={{ height: siderHeight }}>
          <div
            style={{
              position: 'fixed',
              height: siderHeight,
              width: collapsed ? DEFAULT_TREE_WIDTH : '100%',
              backgroundColor: 'white',
              overflow: 'auto',
            }}
          >
            <div className="om-dashboard-common-facility-tree-ant-title">
              <Title level={4}>{title}</Title>
            </div>
            <Divider className="om-dashboard-common-facility-tree-ant-divider" />
            {
              rootTreeNode && (
                <Tree
                  defaultExpandAll
                  blockNode
                  defaultExpandedKeys={defaultExpandedKeys}
                  onSelect={handleSelect}
                  onExpand={handleExpand}
                  style={{
                    width: treeWidth,
                    height: height || 200,
                  }}
                >
                  {renderTreeNodes([rootTreeNode])}
                </Tree>
              )
            }
          </div>
        </Spin>
      </Sider>
    </div>
  );
};

export default OMEquipmentTree;
