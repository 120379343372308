/* eslint-disable @typescript-eslint/no-use-before-define */
// eslint警告未対応
import {
  ExternalDatapoint,
  ExternalDatapointsQuery,
  ItemsWrapper,
  ItemsResponse,
  ExternalEvent,
  CogniteEvent,
} from '@cognite/sdk';
import * as XLSX from 'xlsx';
import { EmptyResponse, putApiGateway, postApiGateway } from '../../../utils/AWS/ApiGateway';
import {
  EP_PATH_SOLAR_TIMESERIES_DATAPOINTS,
  EP_PATH_SOLAR_PROCESSES_PRESUMED_ELECTRIC_ENERGY,
  EP_PATH_SOLAR_EVENTS,
} from '../../../utils/AWS/EndpointPath';
import { sleep } from '../../../utils/common';
import {
  VALIDATIONS_CHECK_ERROR,
  VALIDATIONS_LIMIT_CHECK_ERROR,
  VALIDATIONS_NOT_EXISTS_MANAGEMENT_NO,
  VALIDATIONS_ENTERED_MANAGEMENT_NO_CHECK_ERROR,
  ERROR_FILES_UPLOAD,
} from '../../../utils/messages';
import { getParentBusinessAssetsOfSolarSite } from '../../../utils/Asset/SolarSiteAsset';
import SolarSite from '../../../utils/Asset/SolarSite';

interface RegisterPresumedElectricEnergyResult {
  error: boolean;
  message?: string | string[];
}
// interfaceを実装する時にはインターフェースで定義されているすべてのプロパティに値を設定する必要がある
/**
* siteName（発電所名）の型はstring；
* fiscalYear（登録年度）の型はnumber又はstring；
*/
interface PresumedElectricEnergyParam {
  siteName: string;
  fiscalYear: number | string;
}

/** 計画発電量ファイルフォーマット定義 */
const PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS = {
  ROW: { START: 6, END: 205 },
  COLUMNS: { START: 67, END: 78 }, // C列 ～ N列
  FISCAL_YEAR: 'B4', // 登録年度
  DATA_ARIA: 'A6:N205',
};

/**
 * 計画発電量登録シートの必須（登録年度）項目のバリデーション
 * 登録年度は後続処理に影響があるため型および数値チェックも実施
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns 必須項目のバリデーション結果
 */
const checkRequiredValidity = (sheet: XLSX.Sheet): boolean => {
  const fiscalYear = sheet[PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS.FISCAL_YEAR];

  // データポイントのタイムスタンプ出力時に影響があるので、登録年度の値が1970以上の整数であるかも確認
  const isFiscalYearValid = fiscalYear && Number.isInteger(fiscalYear.v) && fiscalYear.v >= 1970;
  return !!isFiscalYearValid;
};

/**
 * 計画発電量登録シートの登録データ項目のバリデーション
 * 値が記入されている月のデータ（セルC5〜N205）に対して型チェックを実施
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns 登録データ項目のバリデーション結果
 */
const checkDataValidity = (sheet: XLSX.Sheet): boolean => {
  const { ROW, COLUMNS } = PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS;
  for (let rowNumber = ROW.START; rowNumber <= ROW.END; rowNumber++) {
    for (let charCode = COLUMNS.START; charCode <= COLUMNS.END; charCode++) {
      const cell = `${String.fromCharCode(charCode)}${rowNumber}`;
      // 'n'number
      if (sheet[cell] && sheet[cell].t !== 'n') return false;
    }
  }
  return true;
};

/**
 * 計画発電量登録シートのバリデーション
 * 基本情報（登録年度）項目および登録データ項目のチェック結果が両方OKかを確認
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns {RegisterPresumedElectricEnergyResult} バリデーションチェック結果、メッセージ
 */
const checkSheetValidity = (sheet: XLSX.Sheet): RegisterPresumedElectricEnergyResult => {
  if (!sheet) return { error: true, message: VALIDATIONS_CHECK_ERROR };
  const isInfoValid = checkRequiredValidity(sheet);
  const isDataValid = checkDataValidity(sheet);

  if (!checkDataLimitValidity(sheet)) {
    return { error: true, message: VALIDATIONS_LIMIT_CHECK_ERROR };
  }
  if (!checkDataManagementNoValidity(sheet)) {
    return { error: true, message: VALIDATIONS_ENTERED_MANAGEMENT_NO_CHECK_ERROR };
  }
  if (!(isInfoValid && isDataValid)) {
    return { error: true, message: VALIDATIONS_CHECK_ERROR };
  }
  return { error: false };
};

/**
 * 計画発電量シートのデータ件数バリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns データ件数のバリデーション結果
 */
const checkDataLimitValidity = (sheet: XLSX.Sheet): boolean => {
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, number>>(sheet);
  const lastRowData = sheetJson.pop();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-underscore-dangle
  return lastRowData!.__rowNum__ <= (PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS.ROW.END - 1);
};

/**
 * 計画発電量シートの管理No.未入力バリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @returns 管理No.未入力のバリデーション結果
 */
const checkDataManagementNoValidity = (sheet: XLSX.Sheet): boolean => {
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string>>(sheet);
  return !(sheetJson.some((record) => !record.計画発電量登録シート));
};

/**
 * 計画発電量シートの管理Noバリデーション
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @param {SolarSite[]} solarSites 管理Noをmetadataに持つ太陽光アセットリスト
 * @returns データ記入用シートにのみ存在する管理No（すべて存在する場合、空の配列を返却）
 */
const checkManagementNoValidity = async (sheet: XLSX.Sheet, solarSites: SolarSite[]): Promise<string[]> => {
  // 管理番号をmetadataに持つAssetから管理番号のみ抽出
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const managementNos = solarSites.map((solarSite) => solarSite.metadata!.managementNo);
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string>>(
    sheet, { header: ['managementNo'] },
  );

  // アップロードされたファイルにのみ存在する管理番号を抽出
  const onlyInputExistsManagementNo = sheetJson
    .map((sheetJsonItem) => sheetJsonItem.managementNo)
    .filter((managementNo) => !managementNos.includes(managementNo));

  return onlyInputExistsManagementNo;
};

/**
 * 登録年度の各月初日の日付を一覧で取得
 * 登録データの抽出とデータポイントへの変換時に使用
 * @param {number} year 登録年度
 * @returns 登録年度の各月初日の日付
 */
const createHeaderDates = (year: number): string[] => {
  const headerDates: string[] = [];
  headerDates.push('managementNo');
  headerDates.push('siteName');
  for (let i = 4; i <= 15; i++) {
    const yearString = (i > 12 ? year + 1 : year).toString();
    const monthValue = i > 12 ? i - 12 : i;
    const monthString = monthValue < 10 ? `0${monthValue}` : monthValue.toString();
    headerDates.push(`${yearString}-${monthString}-01T00:00:00.000`);
  }
  return headerDates;
};

/**
 * アップロードされた登録データ記入用シートからデータを抽出し、データポイントに変換する
 * @param {XLSX.Sheet} sheet データ記入用シート
 * @param {string[]} headerDates 登録年度の各月初日の日付一覧（データ読み込みとタイムスタンプの変換に使用）
 * @param {SolarSite[]} solarSites 管理Noをmetadataに持つ太陽光アセットリスト
 * @returns 太陽光サイトごとの記入データを変換したデータポイントの配列
 */
const convertFileDataToDatapoints = (
  sheet: XLSX.Sheet,
  headerDates: string[],
  solarSites: SolarSite[],
): { solarSite: SolarSite, datapoints: ExternalDatapoint[] }[] => {
  // シート記載の登録データの値を抽出し、データが1件でもあればデータポイントへの変換を実施
  const sheetJson = XLSX.utils.sheet_to_json<Record<string, string | number>>(
    sheet, { header: headerDates },
  );

  const solarSiteWithDatapoints = sheetJson.map((json) => {
    const { managementNo } = json;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [solarSite] = solarSites.filter((s) => s.metadata!.managementNo === managementNo);

    const datapoints = Object.entries(json)
      .filter(([key]) => !['managementNo', 'siteName'].includes(key)) // 0: managementNo, 1: siteName以外を取り出す
      .map((predictedData) => {
        const timestamp = new Date(predictedData[0]).valueOf(); // valueOfメソッドを使用して、string型に交換する
        const value = predictedData[1];
        return { timestamp, value };
      });

    return { solarSite, datapoints };
  });

  return solarSiteWithDatapoints;
};

/**
 * アップロードされたExcelファイルに記入された計画発電量データをCDFに登録
 * 登録前にファイルの記入内容に対してバリデーションチェックを行い、チェック結果を返却する
 * @param {XLSX.WorkBook} file アップロードファイル
 * @returns ファイル記入内容のバリデーションチェック結果（画面で使用するため）、メッセージ
 */
export const registerPresumedElectricEnergy = async (
  file: XLSX.WorkBook,
): Promise<RegisterPresumedElectricEnergyResult> => {
  // ファイルの中身のバリデーションチェックを実施し、チェックOKの場合のみ後続処理に進む
  const dataSheet = file.Sheets['計画発電量登録シート'];
  const checkSheetResult = checkSheetValidity(dataSheet);
  if (checkSheetResult.error) {
    return checkSheetResult;
  }

  dataSheet['!ref'] = PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS.DATA_ARIA;
  const assetsWithManagementNo = await SolarSite.loadAllAssetWithManagementNos();
  const notExistsManagementNos = await checkManagementNoValidity(dataSheet, assetsWithManagementNo);
  if (notExistsManagementNos.length > 0) {
    return { error: true, message: [VALIDATIONS_NOT_EXISTS_MANAGEMENT_NO, ...notExistsManagementNos] };
  }

  const fiscalYear = dataSheet[PRESUMED_ELECTRIC_ENERGY_CELL_POSITIONS.FISCAL_YEAR].v;
  const headerDates = createHeaderDates(fiscalYear);

  const solarSiteWithDatapoints = convertFileDataToDatapoints(dataSheet, headerDates, assetsWithManagementNo);

  // 登録データが1件でもある場合のみCDFへのデータ登録を実施
  const externalDatapoints: ExternalDatapointsQuery[] = [];
  const presumedElectricEnergyParams: PresumedElectricEnergyParam[] = [];
  solarSiteWithDatapoints.forEach(({ solarSite, datapoints }) => {
    if (datapoints.length === 0) return;

    const externalId = `${solarSite.externalId}_presumed_electric_energy_m`;
    externalDatapoints.push({ externalId, datapoints });

    const siteName = solarSite.externalId as string;
    const bodyItemPresumed: PresumedElectricEnergyParam = { siteName, fiscalYear };
    presumedElectricEnergyParams.push(bodyItemPresumed);
  });

  if (externalDatapoints.length === 0) {
    // 登録データなしの場合
    return { error: false };
  }

  const postResponse = await postApiGateway<ItemsWrapper<ExternalDatapointsQuery[]>, EmptyResponse>(
    EP_PATH_SOLAR_TIMESERIES_DATAPOINTS, { items: externalDatapoints },
  );
  if ('error' in postResponse) {
    return { error: true, message: ERROR_FILES_UPLOAD };
  }

  // 日時積算処理用のイベント作成
  const businessClassificationAssets = await getParentBusinessAssetsOfSolarSite(solarSiteWithDatapoints.map((item) => item.solarSite));
  if (businessClassificationAssets.length > 0) {
    const items = businessClassificationAssets.map((asset) => ({
      dataSetId: asset.dataSetId,
      startTime: new Date().getTime(),
      type: 'presumed_electric_energy_daily_task',
      assetIds: [asset.id],
      metadata: { fiscal_year: fiscalYear },
    }));

    const createBody = { items };
    const createEventsResponse = await postApiGateway<ItemsWrapper<ExternalEvent[]>, ItemsResponse<CogniteEvent[]>>(
      EP_PATH_SOLAR_EVENTS, createBody,
    );
    if ('error' in createEventsResponse) {
      return { error: true, message: ERROR_FILES_UPLOAD };
    }
  }

  // 積算処理を同期で呼び出し
  // eslint-disable-next-line no-restricted-syntax
  for (const bodyItemPresumed of presumedElectricEnergyParams) {
    putApiGateway<PresumedElectricEnergyParam, EmptyResponse>(
      EP_PATH_SOLAR_PROCESSES_PRESUMED_ELECTRIC_ENERGY, bodyItemPresumed,
    );
    /*
      1000ミリ秒/40件=25ミリ秒（1件あたりの秒数）
      1秒あたり40件以下(Lambda内でinsert data pointsを3回呼び出しているためx3)
    */
    // eslint-disable-next-line no-await-in-loop
    await sleep(75);
  }

  return { error: false };
};
