import {
  Asset, AssetListScope, CogniteInternalId, FileFilterProps, FileRequestFilter,
} from '@cognite/sdk';

import { getApiGateway } from '../AWS/ApiGateway';
import {
  EP_PATH_SOLAR_ASSETS,
  EP_PATH_SOLAR_FILES_LIST,
} from '../AWS/EndpointPath';
import BaseAsset from './BaseAsset';
import { loadAllSolarSiteFromCDFByScope } from './SolarSiteAsset';
import TreeNode from '../common/TreeNode';
import { implementSubtreeAssets } from '../common/CogniteApi';
import { ResourceType } from '../common/SDFDataType';
import SolarRegisteredFile from '../File/SolarRegisteredFile';
import { getAllFiles } from '../dataAccess';

class SolarSite extends BaseAsset {
  /*
   * クラスメソッド
   */

  /**
   * 太陽光サイト情報取得
   * @param {CogniteInternalId} id
   * @returns {Promise<SolarSite | null>} 太陽光サイト情報
   */
  static async loadOneByIdFromCDF(id: CogniteInternalId): Promise<SolarSite | null> {
    const getUrl = `${EP_PATH_SOLAR_ASSETS}/${id}`;
    const asset = await getApiGateway<Asset>(getUrl, { ignoreUnknownIds: true });

    if (!asset) return null;

    return new SolarSite(asset);
  }

  /**
   * 管理Noをmetadataに持つ太陽光サイト情報を取得
   * @returns {Promise<SolarSite[]>} 太陽光サイト情報
   */
  static async loadAllAssetWithManagementNos(): Promise<SolarSite[]> {
    // 運用上metadata.assetType = 'sdfasset'のデータは1件のみ
    const scope: AssetListScope = { filter: { metadata: { assetType: 'sdfasset' } } };
    const [solarRootAsset] = await loadAllSolarSiteFromCDFByScope(scope);
    const assets = await implementSubtreeAssets(ResourceType.SolarSite, [{ id: solarRootAsset.id }]);

    const assetsWithManagementNo = assets.items
      .filter((asset) => asset.metadata && asset.metadata.managementNo)
      .map((asset) => new SolarSite(asset));

    return assetsWithManagementNo;
  }

  /*
   * メンバ変数
   */

  /*
   * コンストラクタ
   */
  constructor(asset?: Asset) {
    if (asset) {
      super(asset);
    } else {
      super();
    }
  }

  /*
   * メソッド
   */

  /**
   * クローンオブジェクト生成
   * @returns {SolarSite} 太陽光サイト情報
   */
  cloneSelf(): SolarSite {
    const clone = new SolarSite();
    Object.assign(clone, this);

    return clone;
  }

  // 未使用だが、BaseAsset継承で抽象メソッド定義があるため実体定義
  // eslint-disable-next-line class-methods-use-this
  async loadChildrenFromCDF(): Promise<SolarSite[]> {
    return [];
  }

  /**
   * 太陽光サイトツリー情報取得
   * @returns {Promise<TreeNode<SolarSite>>} 太陽光サイトサブツリー情報
   */
  async loadSolarSiteTreeFromCDF(): Promise<TreeNode<SolarSite>> {
    const assets = await implementSubtreeAssets(ResourceType.SolarSite, [{ id: this.id }]);

    const map: { [key: string]: TreeNode<SolarSite>[] } = {};
    for (let i = 0; i < assets.items.length; i++) {
      const asset = assets.items[i];
      const { parentId, externalId, id } = asset;
      if (parentId) {
        const key = String(parentId);
        if (!map[key]) {
          map[key] = [];
        }
        if (!externalId?.includes('pcs')) {
          map[key].push(new TreeNode(
            String(id), new SolarSite(asset),
          ));
        }
      }
    }

    const node = new TreeNode<SolarSite>(String(this.id), this);
    this.addChildrenToNodeFromMap(node, map);

    return node;
  }

  /**
   * 太陽光登録済みファイルリスト取得
   * @returns {Promise<SolarRegisteredFile[]>} 太陽光登録済みファイルリスト
   */
  async loadSolarRegisteredFiles(): Promise<SolarRegisteredFile[]> {
    const filter: FileFilterProps = {
      assetIds: [this.id],
      uploaded: true,
    };

    const registeredFiles = await getAllFiles(EP_PATH_SOLAR_FILES_LIST, filter);
    const solarRegisteredFiles = registeredFiles.map(
      (registeredFile) => new SolarRegisteredFile(registeredFile),
    );
    return solarRegisteredFiles;
  }

  /**
   * 太陽光財務帳票ファイル検索結果取得
   * @param {FileRequestFilter} fileFilter 検索条件
   * @returns {Promise<SolarRegisteredFile[]>} 検索された財務帳票ファイルリスト
   */
  async loadFinancialReportFilesByFilter(
    fileFilter: FileRequestFilter,
  ): Promise<SolarRegisteredFile[]> {
    const filter: FileFilterProps = {
      assetIds: [this.id],
      uploaded: true,
      ...fileFilter.filter,
    };

    const registeredFiles = await getAllFiles(EP_PATH_SOLAR_FILES_LIST, filter);
    const solarRegisteredFiles = registeredFiles.map(
      (registeredFile) => new SolarRegisteredFile(registeredFile),
    );
    return solarRegisteredFiles;
  }

  /**
   * 太陽光サイトサブツリー情報生成
   * @param {TreeNode<SolarSite>} node
   * @param {{ [key: string]: TreeNode<SolarSite>[] }} map
   */
  private addChildrenToNodeFromMap(
    node: TreeNode<SolarSite>,
    map: { [key: string]: TreeNode<SolarSite>[] },
  ): void {
    const children = map[node.key];
    if (children) {
      node.addChildren(children);
      // eslint-disable-next-line no-param-reassign
      delete map[node.key];
      for (let i = 0; i < node.children.length; i++) {
        this.addChildrenToNodeFromMap(node.children[i], map);
      }
    }
  }
}

export default SolarSite;
