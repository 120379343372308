import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Typography,
  Row,
  Layout,
} from 'antd';

import StatDisplay from './StatDisplay';
import OccurringMeasurementPoint from './OccurringMeasurementPoint';
import { DisplayInfo } from '../OperationAndMaintenance';
import OMAlertEvent from '../../../../utils/Event/OMAlertEvent';
import OMStatusEvent from '../../../../utils/Event/OMStatusEvent';

const { Title } = Typography;
const { Header } = Layout;

interface CommonHeaderDisplayProps {
  areaAssetId: number;
  displayList: DisplayInfo[];
  activeDisplay: DisplayInfo;
  handleButtonClick: (selectedKey: string) => void;
  allAlertEvents: OMAlertEvent[];
}

/**
 * O&M特高監視共通ヘッダーコンポーネント
 * @param {CommonHeaderDisplayProps} props プロパティ
 * @returns O&M特高監視共通ヘッダー
 */
const CommonHeaderDisplay: React.FC<CommonHeaderDisplayProps> = (props: CommonHeaderDisplayProps) => {
  const {
    areaAssetId,
    displayList,
    activeDisplay,
    handleButtonClick,
    allAlertEvents,
  } = props;

  const [statusEventList, setStatusEventList] = useState<OMStatusEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  /*
   * イベントハンドラ
   */

  useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      if (!areaAssetId) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        const statusEvents = await OMStatusEvent.loadStatusEvents(areaAssetId);

        if (!canceled) {
          setStatusEventList(statusEvents);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [loading, areaAssetId],
  );
  /**
   * 発生中の計測ポイントのみ取得
   */
  const occurringMeasurementPointList = allAlertEvents.filter(({ severity, happening }) => severity !== 3 && happening);

  /**
   * 表示されている画面以外の画面への切り替え用ボタン作成処理
   * @param {DisplayInfo[]} selectedDisplayList 切り替えボタン作成対象画面の一覧
   * @returns {JSX.Element[]} 対象画面への切り替え用ボタンの一覧
   */
  const createDisplayButtons = (selectedDisplayList: DisplayInfo[]) => (
    selectedDisplayList.map((display) => (
      <Button key={display.name} style={{ marginRight: 10 }} title={display.name} onClick={() => handleButtonClick(display.key)}>
        {display.name}
      </Button>
    ))
  );

  return (
    <>
      <Header style={{ backgroundColor: '#f0f2f5', height: 'auto' }}>
        <Row>
          <Col span={3} style={{ marginTop: 15, marginLeft: 30 }}>
            <Title level={3}>{activeDisplay.name}</Title>
          </Col>
          <Col style={{ textAlign: 'right', marginRight: 5 }}>
            {createDisplayButtons(displayList)}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col>
            <StatDisplay statusEvents={statusEventList} loading={loading} />
          </Col>
        </Row>
        {!!occurringMeasurementPointList.length && (
          <Row>
            <OccurringMeasurementPoint occurringMeasurementPoints={occurringMeasurementPointList} />
          </Row>
        )}
      </Header>
    </>
  );
};

export default CommonHeaderDisplay;
