import { CogniteEvent, EventFilter } from '@cognite/sdk';

import BaseEvent from './BaseEvent';
import { EP_PATH_SOLAR_OM_EVENTS_LIST } from '../AWS/EndpointPath';
import { getAllEvents } from '../dataAccess';

class OMPcsMeasurementEvent extends BaseEvent {
  /**
   * イベント二つをPCSSortKey順でソート
   * @param {OMPcsMeasurementEvent} a 比較対象イベント（一つ目）
   * @param {OMPcsMeasurementEvent} b 比較対象イベント（二つ目）
   * @returns {number} 比較結果
   */
  static sortByPcsSortKey(a: OMPcsMeasurementEvent, b: OMPcsMeasurementEvent): number {
    if (a.pcsSortKey < b.pcsSortKey) return -1;
    if (a.pcsSortKey > b.pcsSortKey) return 1;

    return 0;
  }

  /**
   * イベント二つをfeeder順でソート
   * @param {OMPcsMeasurementEvent} a 比較対象イベント（一つ目）
   * @param {OMPcsMeasurementEvent} b 比較対象イベント（二つ目）
   * @returns {number} 比較結果
   */
  static sortByFeederNo(a: OMPcsMeasurementEvent, b: OMPcsMeasurementEvent): number {
    if (a.feeder < b.feeder) return -1;
    if (a.feeder > b.feeder) return 1;

    return OMPcsMeasurementEvent.sortByPcsSortKey(a, b);
  }

  /**
   * メンバ変数
   */
  get areaName(): string {
    if (!this.metadata || !this.metadata?.area) return '';
    return this.metadata.area;
  }

  get siteName(): string {
    if (!this.metadata || !this.metadata?.site) return '';
    return this.metadata.site;
  }

  get floorName(): string {
    if (!this.metadata || !this.metadata?.floor) return '';
    return this.metadata.floor;
  }

  get feeder(): number {
    if (!this.metadata || !this.metadata?.feeder) return 0;
    return Number(this.metadata.feeder);
  }

  get pcsName(): string {
    if (!this.metadata || !this.metadata?.pcs_name) return '';
    return this.metadata.pcs_name;
  }

  get outputPower(): number {
    if (!this.metadata || !this.metadata?.output_power) return 0;
    return Number(this.metadata.output_power);
  }

  get outputCurrent(): number {
    if (!this.metadata || !this.metadata?.output_current) return 0;
    return Number(this.metadata.output_current);
  }

  get dcVoltage(): number {
    if (!this.metadata || !this.metadata?.dc_voltage) return 0;
    return Number(this.metadata.dc_voltage);
  }

  get dcCurrent(): number {
    if (!this.metadata || !this.metadata?.dc_current) return 0;
    return Number(this.metadata.dc_current);
  }

  get pcsSortKey(): number {
    if (!this.metadata || !this.metadata?.pcs_sort_key) return 0;
    return Number(this.metadata.pcs_sort_key);
  }

  get hasAlert(): boolean {
    if (!this.metadata
      || !this.metadata?.has_alert
      || !['true', 'false'].includes(this.metadata?.has_alert)
    ) return false;
    return JSON.parse(this.metadata.has_alert);
  }

  /**
   * 対象エリアに紐付く現在発生中のPCS現行計測値一覧を取得する。
   * @param {number} areaAssetId 対象エリアのアセットID
   * @returns {Promise<OMPcsMeasurementEvent[]>} 対象エリア配下の発生中の全PCS現行計測値一覧
   */
  static async loadAllPcsMeasurementEvents(areaAssetId: number): Promise<OMPcsMeasurementEvent[]> {
    const pcsMeasurementEventFilter: EventFilter = {
      assetSubtreeIds: [
        {
          id: areaAssetId,
        },
      ],
      type: 'pcs_measurement',
      subtype: 'latest',
    };

    const pcsMeasurementEvents = await getAllEvents(
      EP_PATH_SOLAR_OM_EVENTS_LIST,
      pcsMeasurementEventFilter,
    ) as CogniteEvent[];

    const pcsAllMeasurementEvents = pcsMeasurementEvents.map((event) => new OMPcsMeasurementEvent(event));
    const sortedPcsAllMeasurementEvents = [...pcsAllMeasurementEvents].sort(OMPcsMeasurementEvent.sortByFeederNo);

    return sortedPcsAllMeasurementEvents;
  }
}

export default OMPcsMeasurementEvent;
