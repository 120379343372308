/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/sort-comp */
/* eslint-disable import/no-extraneous-dependencies */
// eslint警告未対応
import React from 'react';
import {
  Route, HashRouter, Link, Redirect,
} from 'react-router-dom';
import {
  Layout, Menu, Spin, Popconfirm,
} from 'antd';
import { Auth } from 'aws-amplify';
import { createBrowserHistory } from 'history';

import Home from '../Home/Home';
import DigitalTwinApp from '../DigitalTwinApp/DigitalTwinApp';
import Annotation from '../LearningModelManagement/Annotation/Annotation';
import Learning from '../LearningModelManagement/MachineLearning/Learning';
import Detect from '../Detect/Detect';
import DetectResult from '../DetectResult/DetectResult';
import AnnotationTool from '../AnnotationTool/AnnotationTool';
import Task from '../Task/Task';
import InspectionManagement from '../Inspection/InspectionManagement';
import Dashboard from '../DashBoard/Main';
import DataRegistrationTabs from '../Solar/DataRegistrationTabs';
import SolarDashboardSiteSelect from '../Solar/SolarDashboardSiteSelect';
import ElectricEnergyComparisonTabs from '../Solar/ElectricEnergyComparisonTabs';
import ElectricEnergyHistory from '../Solar/ElectricEnergyHistory';
import EquipmentInformation from '../Solar/EquipmentInformation';
import EditEquipmentInformation from '../Solar/EditEquipmentInformation';
import FinancialReportTabs from '../Solar/FinancialReportTabs';
import OperationAndMaintenance from '../Solar/OperationAndMaintenance/OperationAndMaintenance';
import OperationAndMaintenanceDashboard from '../Solar/OperationAndMaintenance/OperationAndMaintenanceDashboard';
import PcsComparisonHistory from '../Solar/PcsComparisonHistory';
import ActivityCheckList from '../Solar/ActivityCheckList';
import { setStorageMenuKey, getStorageMenuKey, removeStorageData } from '../../utils/storageCommon';
import { sdfLogOut } from '../../utils/common';
import { SolarClientGroup } from '../../utils/common/SolarClientGroup';
import { LOGOUT_CONFIRM_MESSAGE } from '../../utils/messages/index';
import {
  windmillUrls, solarUrls, cogniteChartUrl, powerUrls, roadManagementUrl,
} from '../../utils/Links';
import {
  MenuRow, SubMenuRow, MenuIcon, MenuText,
} from './MenuParts';
import './Main.css';

const { Sider, Content, Footer } = Layout;

/**
 * Menu部component
 * @property {object} client CogniteClient
 */
class Main extends React.Component {
  state = {
    collapsed: false,
    selectedPage: 1,
    selectedMenuKey: '1',
    loadingFlag: false,
    authorization: undefined,
  };

  /**
   * Menu展開時(下の">"ボタン)のイベントハンドラ
   * @param {boolean} collapsed
   */
  onCollapse = (collapsed) => this.setState({ collapsed });

  /**
   * Menuクリック時のイベントハンドラ
   * 選択したMenuのkeyをストレージに格納
   * @param {object} item 選択したMenu Item
   */
  onClickMenu = (item) => {
    this.setSelectedMenuKey(item.key);
  };

  /**
   * 風力・太陽光メニュークリック時のイベントハンドラ
   * 対応するURLを別ウィンドウで開く
   * @param {string} url 対象のURL
   */
  onClickOpenWindow = (url) => {
    window.open(url, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes,noreferrer');
  };

  /**
   * 道路メニュークリック時のイベントハンドラ
   * 対応するURLを別ウィンドウで開く
   * @param {string} url 対象のURL
   */
  onClickOpenWindowRoad = (url, json) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomStr = '';
    for (let i = 0; i < 40; i++) {
      randomStr += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    const encodedStr = btoa(randomStr);
    window.localStorage.setItem(json, encodedStr);
    window.open(url, null, 'width=500,toolbar=yes,menubar=yes,scrollbars=yes,noreferrer');
  };

  /**
   * ログアウトボタンクリック時のイベントハンドラ
   */
  onClickLogOut = async () => {
    sdfLogOut();
    await Auth.signOut();
  };

  /**
   * MenuKeyの設定
   * @param {string} keyMenuKey
   */
  setSelectedMenuKey = (key) => {
    setStorageMenuKey(key);
    this.setState({
      selectedMenuKey: key,
    });
  };

  /**
   * ページ番号の設定
   * @param {number} num 選択しているページ数
   */
  setSelectedPage = (num) => {
    this.setState({
      selectedPage: num,
    });
  };

  /**
   * ロードフラグの設定
   * @param {boolean} flag Loading Flag (true: Start Loading / false:Loading Finish)
   */
  setLoadingFlag = (flag) => {
    this.setState({
      loadingFlag: flag,
    });
  };

  /**
   * MenuKeyの取得
   * @returns {string} MenuKey
   */
  getMenuKey = () => {
    const history = createBrowserHistory();
    const { hash } = history.location;

    if (hash === '#/') {
      return '1';
    }
    return getStorageMenuKey();
  };

  /**
   * MenuItemとRoute、FooterMenuの作成
   * @returns {object} MenuItemとRouteの配列オブジェクト、FooterMenuオブジェクト
   */
  generateMenuItemsWithRoute = () => {
    const { collapsed, authorization } = this.state;

    if (!authorization) return { menuItems: [], routes: [], footerMenu: undefined };
    const { accessibleScreenList } = authorization;

    const menuItems = [];
    const routes = [];

    // 設備一覧
    if (accessibleScreenList.includes('home')) {
      menuItems.push(
        <Menu.Item key="home-menu">
          <MenuRow to="/" icon="./icon/Home.png" title="設備一覧" />
        </Menu.Item>,
      );
      routes.push(
        <Route key="home-key" exact path="/" render={() => (
          <Home client={this.props}
            selectedPage={this.state.selectedPage}
            setSelectedPage={this.setSelectedPage}
            setSelectedMenuKey={this.setSelectedMenuKey}
            setLoadingFlag={this.setLoadingFlag}
          />
        )}
        />,
      );
    }

    // 設備詳細
    if (accessibleScreenList.includes('3DView')) {
      menuItems.push(
        <Menu.Item key="3D_view-menu">
          <MenuRow to="/3DView" icon="./icon/View.png" title="設備詳細" />
        </Menu.Item>,
      );
      routes.push(
        <Route key="3D-view-key" path="/3DView" render={() => (
          <DigitalTwinApp client={this.props} setLoadingFlag={this.setLoadingFlag} menuCollapsed={collapsed} />
        )}
        />,
      );
    }

    // 学習モデル管理
    const isDisplayAnnotation = accessibleScreenList.includes('annotation');
    const isDisplayLearning = accessibleScreenList.includes('learning');
    if (isDisplayAnnotation || isDisplayLearning) {
      const subMenuItems = [];
      if (isDisplayAnnotation) {
        // アノテーション
        subMenuItems.push(
          <Menu.Item key="annotation-menu">
            <Link to="/Annotation">
              <span className="submenu-text">アノテーション</span>
            </Link>
          </Menu.Item>,
        );
        routes.push(
          <Route key="annotation-key" path="/Annotation" render={() => (
            <Annotation client={this.props} setLoadingFlag={this.setLoadingFlag} />
          )}
          />,
        );
      }

      if (isDisplayLearning) {
        // 機械学習
        subMenuItems.push(
          <Menu.Item key="learning-menu">
            <Link to="/Learning">
              <span className="submenu-text">機械学習</span>
            </Link>
          </Menu.Item>,
        );
        routes.push(
          <Route key="learning-key" path="/Learning" render={() => (
            <Learning client={this.props} setLoadingFlag={this.setLoadingFlag} />
          )}
          />,
        );
      }

      menuItems.push(
        <SubMenuRow
          key="project-submenu-learning-model"
          icon="./icon/Annotation.png"
          title="学習モデル管理"
          subMenuItems={subMenuItems}
          {...this.props}
        />,
      );
    }

    // AI検出
    if (accessibleScreenList.includes('detect')) {
      menuItems.push(
        <Menu.Item key="detect-menu">
          <MenuRow to="/Detect" icon="./icon/Detect.png" title="AI検出" />
        </Menu.Item>,
      );
      routes.push(
        <Route key="detect-key" path="/Detect" render={() => (
          <Detect client={this.props} setLoadingFlag={this.setLoadingFlag} menuCollapsed={collapsed} />
        )}
        />,
      );
    }

    // AI検出結果
    if (accessibleScreenList.includes('detectResult')) {
      menuItems.push(
        <Menu.Item key="detect_result-menu">
          <MenuRow to="/DetectResult" icon="./icon/Result.png" title="AI検出結果" />
        </Menu.Item>,
      );
      routes.push(
        <Route key="detect-result-key" path="/DetectResult" render={() => (
          <DetectResult client={this.props} setLoadingFlag={this.setLoadingFlag} menuCollapsed={collapsed} />
        )}
        />,
      );
    }

    // タスク一覧
    if (accessibleScreenList.includes('task')) {
      menuItems.push(
        <Menu.Item key="aitask-menu">
          <MenuRow to="/Task" icon="./icon/AiTask.png" title="タスク一覧" />
        </Menu.Item>,
      );
      routes.push(
        <Route key="task-key" path="/Task" render={() => (
          <Task setLoadingFlag={this.setLoadingFlag} />
        )}
        />,
      );
    }

    // 点検結果一覧
    const isDisplayInspectionResults = accessibleScreenList.includes('inspectionResults');
    if (isDisplayInspectionResults) {
      menuItems.push(
        <Menu.Item key="inspection-result">
          <MenuRow to="/inspection/results" icon="./icon/Inspection.png" title="点検結果一覧" />
        </Menu.Item>,
      );
      routes.push(
        <Route key="inspection-result-key" path="/inspection/results" render={() => (
          <InspectionManagement client={this.props.client} setLoadingFlag={this.setLoadingFlag} />
        )}
        />,
      );
    } else {
      // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
      routes.push(
        <Route key="inspection-result-key" path="/inspection/results" render={() => <Redirect to="/" />} />,
      );
    }

    // ダッシュボード
    const isDisplayDashBoard = accessibleScreenList.includes('dashboard');
    if (isDisplayDashBoard) {
      menuItems.push(
        <Menu.Item key="Dashboard-menu">
          <MenuRow to="/Dashboard" icon="./icon/Dashboard.png" title="ダッシュボード" />
        </Menu.Item>,
      );
      routes.push(
        <Route key="dashboard-key" path="/Dashboard" render={() => (
          <Dashboard client={this.props.client} setLoadingFlag={this.setLoadingFlag} />
        )}
        />,
      );
    } else {
      // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
      routes.push(
        <Route key="dashboard-key" path="/Dashboard" render={() => <Redirect to="/" />} />,
      );
    }

    // 電源統合メニュー
    const isDisplayPowerManagementDashboard = accessibleScreenList.includes('powerManagementDashboard');
    if (isDisplayPowerManagementDashboard) {
      const subMenuItems = [];
      // 経営ダッシュボード
      subMenuItems.push(
        <Menu.Item key="power-management-dashboard" onClick={() => this.onClickOpenWindow(powerUrls.management)}>
          <span className="submenu-text">経営ダッシュボード</span>
        </Menu.Item>,
      );

      menuItems.push(
        <SubMenuRow
          key="project-submenu-powerIntegration-dashboard"
          icon="./icon/PowerIntegration.png"
          title="電源統合メニュー"
          subMenuItems={subMenuItems}
          {...this.props}
        />,
      );
    }

    // 風力メニュー
    const isDisplayManagementDashboard = accessibleScreenList.includes('managementDashboard');
    const isDisplayOperationDashboard = accessibleScreenList.includes('operationDashboard');
    const isDisplayBirdDetectionDashboard = accessibleScreenList.includes('birdDetectionDashboard');
    if (isDisplayManagementDashboard || isDisplayOperationDashboard || isDisplayBirdDetectionDashboard) {
      const subMenuItems = [];
      if (isDisplayManagementDashboard) {
        // 経営ダッシュボード
        subMenuItems.push(
          <Menu.Item key="management-dashboard" onClick={() => this.onClickOpenWindow(windmillUrls.management)}>
            <span className="submenu-text">経営ダッシュボード</span>
          </Menu.Item>,
        );
      }

      if (isDisplayOperationDashboard) {
        // 運用ダッシュボード
        subMenuItems.push(
          <Menu.Item key="operation-dashboard" onClick={() => this.onClickOpenWindow(windmillUrls.operation)}>
            <span className="submenu-text">運用ダッシュボード</span>
          </Menu.Item>,
        );
      }

      if (isDisplayBirdDetectionDashboard) {
        // 鳥検知ダッシュボード
        subMenuItems.push(
          <Menu.Item key="bird-detection-menu" onClick={() => this.onClickOpenWindow(windmillUrls.birdDetection)}>
            <span className="submenu-text">鳥検知ダッシュボード</span>
          </Menu.Item>,
        );
      }

      menuItems.push(
        <SubMenuRow
          key="project-submenu-windmill-dashboard"
          icon="./icon/Windmill.png"
          title="風力メニュー"
          subMenuItems={subMenuItems}
          {...this.props}
        />,
      );
    }

    // 太陽光メニュー
    const isDisplaySolarManagementDashboard = accessibleScreenList.includes('solarManagementDashboard');
    const isDisplaySolarOperationDashboard = accessibleScreenList.includes('solarOperationDashboard');
    const isDisplaySolarIndividualAnalysis = accessibleScreenList.includes('solarIndividualAnalysis');
    const isDisplaySolarElectricEnergyHistory = accessibleScreenList.includes('solarElectricEnergyHistory');
    const isDisplaySolarEquipmentInformation = accessibleScreenList.includes('solarEquipmentInformation');
    const isDisplaySolarFinancialReportManagement = accessibleScreenList.includes('solarFinancialReportManagement');
    const isDisplaySolarSiteComparison = accessibleScreenList.includes('solarSiteComparison');
    const isDisplaySolarElectricEnergyAnalysis = accessibleScreenList.includes('solarElectricEnergyAnalysis');
    const isDisplaySolarOperationStatusList = accessibleScreenList.includes('solarOperationStatusList');
    const isDisplaySolarFileUpload = accessibleScreenList.includes('solarFileUpload');
    const isDisplaySolarFinancialReportRegistration = accessibleScreenList.includes('solarFinancialReportRegistration');
    const isDisplaySolarOperationStatusInput = accessibleScreenList.includes('solarOperationStatusInput');
    const isDisplaySolarPowerPlantCatalogEditing = accessibleScreenList.includes('solarPowerPlantCatalogEditing');
    const isDisplaySolarUploadFileList = accessibleScreenList.includes('solarUploadFileList');
    const isDisplaySolarMaintenanceDashboard = accessibleScreenList.includes('solarMaintenanceDashboard');
    const isDisplaySolarOMDashboard = accessibleScreenList.includes('solarOMDashboard');
    const isDisplaySolarElectricEnergyComparisonHistory = accessibleScreenList.includes('solarElectricEnergyComparisonHistory');
    const isDisplaySolarPcsComparisonHistory = accessibleScreenList.includes('solarPcsComparisonHistory');
    const isDisplaySolarActivityCheckHistory = accessibleScreenList.includes('solarActivityCheckHistory');
    // 個別分析メニューの権限制御
    const isDisplayIndividualAnalysisMenu = isDisplaySolarSiteComparison
      || isDisplaySolarElectricEnergyAnalysis
      || isDisplaySolarIndividualAnalysis;
    // データ参照メニューの権限制御
    const isDisplayDataReferenceMenu = isDisplaySolarUploadFileList
      || isDisplaySolarElectricEnergyHistory
      || isDisplaySolarFinancialReportManagement
      || isDisplaySolarOperationStatusList
      || isDisplaySolarElectricEnergyComparisonHistory
      || isDisplaySolarPcsComparisonHistory
      || isDisplaySolarActivityCheckHistory;
    // メンテナンスメニューの権限制御
    const isDisplayMaintenanceMenu = isDisplaySolarFileUpload
      || isDisplaySolarFinancialReportRegistration
      || isDisplaySolarOperationStatusInput
      || isDisplaySolarPowerPlantCatalogEditing;
    // 太陽光メニューの権限制御
    const isDisplaySolarMenu = isDisplaySolarManagementDashboard
      || isDisplaySolarOperationDashboard
      || isDisplaySolarEquipmentInformation
      || isDisplayIndividualAnalysisMenu
      || isDisplaySolarMaintenanceDashboard
      || isDisplaySolarOMDashboard
      || isDisplayDataReferenceMenu
      || isDisplayMaintenanceMenu;

    if (isDisplaySolarMenu) {
      const subMenuItems = [];
      if (isDisplaySolarManagementDashboard) {
        // 経営ダッシュボード
        subMenuItems.push(
          <Menu.Item key="solar-management-dashboard" onClick={() => this.onClickOpenWindow(solarUrls.management)}>
            <span className="submenu-text">経営ダッシュボード</span>
          </Menu.Item>,
        );
      }

      if (isDisplaySolarOperationDashboard) {
        // 運用ダッシュボード
        subMenuItems.push(
          <Menu.Item key="solar-operation-dashboard-menu">
            <Link to="/SolarOperationDashboard">
              <span className="submenu-text">運用ダッシュボード</span>
            </Link>
          </Menu.Item>,
        );
        routes.push(
          <Route key="solar-operation-dashboard-key" path="/SolarOperationDashboard" render={() => (
            // JSファイルでは型定義を利用できないため、clientGroupを文字列として直接使用している
            <SolarDashboardSiteSelect clientGroup={SolarClientGroup.AeG} onClickDashboardMenu={this.onClickOpenWindow} />
          )}
          />,
        );
      } else {
        // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
        routes.push(
          <Route key="solar-operation-dashboard-key" path="/SolarOperationDashboard" render={() => <Redirect to="/" />} />,
        );
      }

      // 個別分析
      if (isDisplayIndividualAnalysisMenu) {
        const individualAnalysisMenuItems = [];
        if (isDisplaySolarSiteComparison) {
          // サイト間比較
          individualAnalysisMenuItems.push(
            <Menu.Item key="solar-site-comparison" onClick={() => this.onClickOpenWindow(solarUrls.siteComparison)}>
              <span className="submenu-text">サイト間比較</span>
            </Menu.Item>,
          );
        }
        if (isDisplaySolarElectricEnergyAnalysis) {
          // 発電量差異分析
          individualAnalysisMenuItems.push(
            <Menu.Item key="solar-electric-energy-analysis">
              <span className="submenu-text">発電量差異分析</span>
            </Menu.Item>,
          );
        }
        if (isDisplaySolarIndividualAnalysis) {
          // 汎用時系列分析
          individualAnalysisMenuItems.push(
            <Menu.Item key="solar-individual-analysis" onClick={() => this.onClickOpenWindow(cogniteChartUrl)}>
              <span className="submenu-text">汎用時系列分析</span>
            </Menu.Item>,
          );
        }
        subMenuItems.push(
          <SubMenuRow
            key="solar-individual-analysis-menu"
            title="個別分析"
            subMenuItems={individualAnalysisMenuItems}
            {...this.props}
          />,
        );
      } else {
        // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
        // TODO: 発電量差異分析のページ遷移が完成していないためコメントアウト
        // routes.push(
        //   <Route key="solar-individual-analysis" path="/SolarIndividualAnalysis" render={() => <Redirect to="/" />} />,
        // );
      }

      if (isDisplaySolarMaintenanceDashboard) {
        // 保守ダッシュボード（O＆M向け）
        subMenuItems.push(
          <Menu.Item key="solar-maintenance-dashboard-menu">
            <Link to="/SolarMaintenanceDashboard">
              <span className="submenu-text">保守ダッシュボード</span>
            </Link>
          </Menu.Item>,
        );
        routes.push(
          <Route key="solar-maintenance-dashboard-key" path="/SolarMaintenanceDashboard" render={() => (
            // JSファイルでは型定義を利用できないため、clientGroupを文字列として直接使用している
            <SolarDashboardSiteSelect clientGroup={SolarClientGroup.AeEgs} onClickDashboardMenu={this.onClickOpenWindow} />
          )}
          />,
        );
      } else {
        // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
        routes.push(
          <Route key="solar-maintenance-dashboard-key" path="/SolarMaintenanceDashboard" render={() => <Redirect to="/" />} />,
        );
      }

      if (isDisplaySolarOMDashboard) {
        // 特高監視ダッシュボード（O＆M向け）
        subMenuItems.push(
          <Menu.Item key="operation-and-maintenance-dashboard">
            <Link to="/OperationAndMaintenanceDashboard">
              <span className="submenu-text">特高監視ダッシュボード</span>
            </Link>
          </Menu.Item>,
        );
        routes.push(
          <Route key="operation-and-maintenance-dashboard-key" path="/OperationAndMaintenanceDashboard" render={() => (
            <OperationAndMaintenanceDashboard />
          )}
          />,
          <Route key="operation-and-maintenance-key" path="/OperationAndMaintenance" render={() => (
            <OperationAndMaintenance />
          )}
          />,
        );
      } else {
        routes.push(
          <Route key="operation-and-maintenance-dashboard-key" path="/OperationAndMaintenanceDashboard" render={() => <Redirect to="/" />} />,
          <Route key="operation-and-maintenance-key" path="/OperationAndMaintenance" render={() => <Redirect to="/" />} />,
        );
      }

      // 発電所カタログ
      if (isDisplaySolarEquipmentInformation) {
        subMenuItems.push(
          <Menu.Item key="solar-equipment-information">
            <Link to="/SolarEquipmentInformation">
              <span className="submenu-text">発電所カタログ</span>
            </Link>
          </Menu.Item>,
        );
        routes.push(
          <Route key="solar-equipment-information-key" path="/SolarEquipmentInformation" render={() => (
            <EquipmentInformation />
          )}
          />,
        );
      } else {
        // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
        routes.push(
          <Route key="solar-equipment-information-key" path="/SolarEquipmentInformation" render={() => <Redirect to="/" />} />,
        );
      }

      // データ参照
      if (isDisplayDataReferenceMenu) {
        const dataReferenceMenuItems = [];
        if (isDisplaySolarUploadFileList) {
          // アップロードファイル一覧
          dataReferenceMenuItems.push(
            <Menu.Item key="solar-upload-file-list" className="submenu-text">
              <Link to="/SolarUploadFileList">
                <span className="submenu-text">アップロードファイル一覧</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-upload-file-list-key" path="/SolarUploadFileList" render={() => (
              <DataRegistrationTabs setLoadingFlag={this.setLoadingFlag} display="一覧" />
            )}
            />,
          );
        } else {
          // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
          routes.push(
            <Route key="solar-upload-file-list-key" path="/SolarUploadFileList" render={() => <Redirect to="/" />} />,
          );
        }

        if (isDisplaySolarElectricEnergyHistory) {
          // 発電量ステータス一覧
          dataReferenceMenuItems.push(
            <Menu.Item key="solar-electric-energy-history">
              <Link to="/SolarElectricEnergyHistory">
                <span className="submenu-text">発電量ステータス一覧</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-electric-energy-history-key" path="/SolarElectricEnergyHistory" render={() => (
              <ElectricEnergyHistory />
            )}
            />,
          );
        } else {
          // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
          routes.push(
            <Route key="solar-electric-energy-history-key" path="/SolarElectricEnergyHistory" render={() => <Redirect to="/" />} />,
          );
        }
        if (isDisplaySolarFinancialReportManagement) {
          // 財務情報閲覧
          dataReferenceMenuItems.push(
            <Menu.Item key="solar-financial-report-management">
              <Link to="/SolarFinancialReportManagement">
                <span className="submenu-text">財務情報閲覧</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-financial-report-management-key" path="/SolarFinancialReportManagement" render={() => (
              <FinancialReportTabs setLoadingFlag={this.setLoadingFlag} display="検索" />
            )}
            />,
          );
        } else {
          // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
          routes.push(
            <Route key="solar-financial-report-management-key" path="/SolarFinancialReportManagement" render={() => <Redirect to="/" />} />,
          );
        }
        if (isDisplaySolarOperationStatusList) {
          // 運転状況一覧
          dataReferenceMenuItems.push(
            <Menu.Item key="solar-operation-status-list">
              <span className="submenu-text">運転状況一覧</span>
            </Menu.Item>,
          );
        }
        if (isDisplaySolarElectricEnergyComparisonHistory) {
          // 発電量診断結果一覧（O&M向け）
          dataReferenceMenuItems.push(
            <Menu.Item key="solar-electric-energy-comparison-history">
              <Link to="/SolarElectricEnergyComparisonHistory">
                <span className="submenu-text">発電量診断結果一覧</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-electric-energy-comparison-history" path="/SolarElectricEnergyComparisonHistory" render={() => (
              <ElectricEnergyComparisonTabs />
            )}
            />,
          );
        } else {
          routes.push(
            <Route key="solar-electric-energy-comparison-history" path="/SolarElectricEnergyComparisonHistory" render={() => <Redirect to="/" />} />,
          );
        }
        if (isDisplaySolarPcsComparisonHistory) {
          // PCS比較診断結果一覧（O&M向け）
          dataReferenceMenuItems.push(
            <Menu.Item key="solar-pcs-comparison-history">
              <Link to="/SolarPcsComparisonHistory">
                <span className="submenu-text">PCS比較診断結果一覧</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-pcs-comparison-history" path="/SolarPcsComparisonHistory" render={() => (
              <PcsComparisonHistory />
            )}
            />,
          );
        } else {
          routes.push(
            <Route key="solar-pcs-comparison-history" path="/SolarPcsComparisonHistory" render={() => <Redirect to="/" />} />,
          );
        }
        if (isDisplaySolarActivityCheckHistory) {
          // 死活監視結果一覧（O&M向け）
          dataReferenceMenuItems.push(
            <Menu.Item key="solar-activity-check-history">
              <Link to="/SolarActivityCheckHistory">
                <span className="submenu-text">死活監視結果一覧</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-activity-check-history-key" path="/SolarActivityCheckHistory" render={() => (
              <ActivityCheckList />
            )}
            />,
          );
        } else {
          routes.push(
            <Route key="solar-activity-check-history" path="/SolarActivityCheckHistory" render={() => <Redirect to="/" />} />,
          );
        }
        subMenuItems.push(
          <SubMenuRow
            key="solar-data-reference-menu"
            title="データ参照"
            subMenuItems={dataReferenceMenuItems}
            {...this.props}
          />,
        );
      } else {
        // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
        routes.push(
          <Route key="solar-upload-file-list" path="/SolarUploadFileList" render={() => <Redirect to="/" />} />,
        );
        routes.push(
          <Route key="solar-electric-energy-history" path="/SolarElectricEnergyHistory" render={() => <Redirect to="/" />} />,
        );
        routes.push(
          <Route key="solar-financial-report-management" path="/SolarFinancialReportManagement" render={() => <Redirect to="/" />} />,
        );
        routes.push(
          <Route key="solar-electric-energy-comparison-history-key" path="/SolarElectricEnergyComparisonHistory" render={() => <Redirect to="/" />} />,
        );
        routes.push(
          <Route key="solar-pcs-comparison-history" path="/SolarPcsComparisonHistory" render={() => <Redirect to="/" />} />,
        );
        routes.push(
          <Route key="solar-activity-check-history" path="/SolarActivityCheckHistory" render={() => <Redirect to="/" />} />,
        );
        // TODO: 運転状況一覧のページ遷移が完成していないためコメントアウト
        // routes.push(
        //   <Route key="solar-operation-status-list" path="/SolarOperationStatusList" render={() => <Redirect to="/" />} />,
        // );
      }

      // メンテナンス
      if (isDisplayMaintenanceMenu) {
        const maintenanceMenuItems = [];
        if (isDisplaySolarFileUpload) {
          // ファイルアップロード
          maintenanceMenuItems.push(
            <Menu.Item key="solar-fileUpload">
              <Link to="/SolarFileUpload">
                <span className="submenu-text">ファイルアップロード</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-fileUpload-key" path="/SolarFileUpload" render={() => (
              <DataRegistrationTabs setLoadingFlag={this.setLoadingFlag} display="登録" />
            )}
            />,
          );
        } else {
          // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
          routes.push(
            <Route key="solar-fileUpload-key" path="/SolarFileUpload" render={() => <Redirect to="/" />} />,
          );
        }
        if (isDisplaySolarFinancialReportRegistration) {
          // 財務帳票登録
          maintenanceMenuItems.push(
            <Menu.Item key="solar-financial-report-registration">
              <Link to="/SolarFinancialReportRegistration">
                <span className="submenu-text">財務帳票登録</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-financial-report-registration-key" path="/SolarFinancialReportRegistration" render={() => (
              <FinancialReportTabs setLoadingFlag={this.setLoadingFlag} display="登録" />
            )}
            />,
          );
        } else {
          // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
          routes.push(
            <Route key="solar-financial-report-registration-key" path="/SolarFinancialReportRegistration" render={() => <Redirect to="/" />} />,
          );
        }
        if (isDisplaySolarOperationStatusInput) {
          // 運転状況入力
          maintenanceMenuItems.push(
            <Menu.Item key="solar-operation-status-input">
              <span className="submenu-text">運転状況入力</span>
            </Menu.Item>,
          );
        }
        if (isDisplaySolarPowerPlantCatalogEditing) {
          // 発電所カタログ編集
          maintenanceMenuItems.push(
            <Menu.Item key="solar-edit-equipment-information">
              <Link to="/EditSolarEquipmentInformation">
                <span className="submenu-text">発電所カタログ編集</span>
              </Link>
            </Menu.Item>,
          );
          routes.push(
            <Route key="solar-edit-equipment-information-key" path="/EditSolarEquipmentInformation" render={() => (
              <EditEquipmentInformation />
            )}
            />,
          );
        } else {
          // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
          routes.push(
            <Route key="solar-edit-equipment-information-key" path="/EditSolarEquipmentInformation" render={() => <Redirect to="/" />} />,
          );
        }
        subMenuItems.push(
          <SubMenuRow
            key="solar-maintenance-menu"
            title="メンテナンス"
            subMenuItems={maintenanceMenuItems}
            {...this.props}
          />,
        );
      } else {
        // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
        routes.push(
          <Route key="solar-fileUpload" path="/SolarFileUpload" render={() => <Redirect to="/" />} />,
        );
        routes.push(
          <Route key="solar-financial-report-registration" path="/SolarFinancialReportRegistration" render={() => <Redirect to="/" />} />,
        );
        // TODO: 運転状況入力のページ遷移が完成していないためコメントアウト
        // routes.push(
        //   <Route key="solar-operation-status-input" path="/SolarOperationStatusInput" render={() => <Redirect to="/" />} />,
        // );
        routes.push(
          <Route key="solar-edit-equipment-information" path="/EditSolarEquipmentInformation" render={() => <Redirect to="/" />} />,
        );
      }

      menuItems.push(
        <SubMenuRow
          key="project-submenu-solar-dashboard"
          icon="./icon/Solar.png"
          title="太陽光メニュー"
          subMenuItems={subMenuItems}
          {...this.props}
        />,
      );
    } else {
      // 権限によって非表示となる可能性のある画面は設備一覧画面にリダイレクトするようにする
      routes.push(
        <Route key="solar-operation-dashboard-key" path="/SolarOperationDashboard" render={() => <Redirect to="/" />} />,
      );
    }

    // 道路運営管理
    const isDisplayDigitalTwin = accessibleScreenList.includes('roadManagement');
    if (isDisplayDigitalTwin) {
      const subMenuItems = [];

      if (isDisplayDigitalTwin) {
        // 道路運営管理デジタルツイン
        let initJson;
        if (this.props.roadproject) {
          initJson = this.props.roadproject;
        } else {
          initJson = 'sample';
        }
        subMenuItems.push(
          <Menu.Item key="road-management-digital-twin" onClick={() => this.onClickOpenWindowRoad(roadManagementUrl + '#' + initJson, initJson)}>
            <span className="submenu-text">道路運営管理デジタルツイン</span>
          </Menu.Item>,
        );
      }

      menuItems.push(
        <SubMenuRow
          key="project-submenu-road-management"
          icon="./icon/Road.svg"
          title="道路メニュー"
          subMenuItems={subMenuItems}
          {...this.props}
        />,
      );
    }

    // VIA
    routes.push(
      <Route key="via-key" path="/AnnotationTool" render={() => (
        <AnnotationTool client={this.props} />
      )}
      />,
    );

    const manualSubMenuItems = [];
    // マニュアル：共通メニュー
    manualSubMenuItems.push(
      <Menu.Item key="common-manual" onClick={() => window.open('/SDF_CommonMenu_UserManual.pdf')}>
        <span className="submenu-text">共通メニュー</span>
      </Menu.Item>,
    );

    // マニュアル：電源統合メニュー
    if (isDisplayPowerManagementDashboard) {
      manualSubMenuItems.push(
        <Menu.Item key="power-integration-manual" onClick={() => window.open('/SDF_PowerIntegrationMenu_UserManual.pdf')}>
          <span className="submenu-text">電源統合メニュー</span>
        </Menu.Item>,
      );
    }

    // マニュアル：太陽光メニュー
    if (isDisplaySolarMenu) {
      manualSubMenuItems.push(
        <Menu.Item key="solar-manual" onClick={() => window.open('/SDF_SolarPowerMenu_UserManual.pdf')}>
          <span className="submenu-text">太陽光メニュー</span>
        </Menu.Item>,
      );
    }

    // マニュアルメニュー
    const manualMenu = (
      <SubMenuRow
        key="manual-submenu"
        icon="./icon/Manual.png"
        title="マニュアル"
        subMenuItems={manualSubMenuItems}
        {...this.props}
      />
    );

    // フッターメニュー
    const footerMenu = (
      <Menu mode="inline" theme="dark">
        {manualMenu}
        <Menu.Item key="logout">
          <Popconfirm
            placement="topLeft"
            title={LOGOUT_CONFIRM_MESSAGE}
            onConfirm={this.onClickLogOut}
            okText="OK"
            cancelText="Cancel"
          >
            <div className="menu-item">
              <MenuIcon src="./icon/Logout.png" alt="ログアウト" />
              <MenuText title="ログアウト" />
            </div>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return { menuItems, routes, footerMenu };
  };

  /**
   * component render前の処理
   */
  async componentDidMount() {
    const menuKey = this.getMenuKey();

    const cache = await caches.open('sdf-preset-ap-func');
    const cacheData = await cache.match('/authFunction');
    const data = await cacheData.json();

    this.setState({
      selectedMenuKey: menuKey,
      authorization: data.PermissionFunction,
    });
  }

  /**
   * component破棄時の処理
   * ストレージに格納した情報を削除
   */
  componentWillUnmount() {
    removeStorageData();
  }

  render() {
    const { collapsed } = this.state;
    const { menuItems, routes, footerMenu } = this.generateMenuItemsWithRoute();

    return (
      <div style={{ width: '100%' }}>
        <Spin spinning={this.state.loadingFlag} size="large" tip="Loading..." style={{ marginTop: '10%' }}>
          <Layout style={{ height: '100vh', overflow: 'auto' }}>
            <HashRouter>
              <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{ height: '100vh', whiteSpace: 'nowrap' }} width={250} className="site-layout-background">
                <Content style={{ overflow: 'auto', height: '88%' }}>
                  <Menu mode="inline" theme="dark" selectedKeys={[this.state.selectedMenuKey]} onClick={this.onClickMenu} defaultOpenKeys={['project-submenu-learning-model']}>
                    {menuItems && Object.keys(menuItems).map((item) => menuItems[item])}
                  </Menu>
                </Content>
                <Footer style={{
                  bottom: '60px', position: 'absolute', width: '100%', padding: '0', background: '#001529',
                }}
                >
                  {footerMenu}
                </Footer>
              </Sider>
              <Layout style={{ height: '100%', overflow: 'auto' }}>
                {routes && Object.keys(routes).map((item) => routes[item])}
              </Layout>
            </HashRouter>
          </Layout>
        </Spin>
      </div>
    );
  }
}

export default Main;
