import env from '../../sdf-env.json';

const GRAFANA_WINDMILL_URL = 'https://grafana.smartdatafusion.jp';
const GRAFANA_SOLAR_URL = 'https://grafana-solar.smartdatafusion.jp';

export const windmillUrls = {
  management: `${GRAFANA_WINDMILL_URL}/d/6Ge-r--7k/huamuxuan-ze?orgId=1`,
  operation: `${GRAFANA_WINDMILL_URL}/d/S8Vmlob7k/quan-ti-yun-yong-zhuang-kuang-_huamua?orgId=1&refresh=5s`,
  birdDetection: `${GRAFANA_WINDMILL_URL}/d/9PncyNB7z/niao-kaunto_huamua-1hao-ji?orgId=1&refresh=5s`,
};

export const solarUrls = {
  management: `${GRAFANA_SOLAR_URL}/d/CjTiUfn4z/suo-you-saitoquan-ti-ba-wo?orgId=1`,
  siteComparison: `${GRAFANA_SOLAR_URL}/d/F2g0wNnIz/saitojian-bi-jiao-_zhong-yao-fa-dian-zhi-biao?orgId=1`,
};

export const powerUrls = {
  management: `${GRAFANA_SOLAR_URL}/d/9wWL_6yVz/dian-yuan-tong-he-datsushiyubodo_yue-bie?orgId=1`,
};

export const cogniteChartUrl = 'https://nttcom.fusion.cognite.com/nttae01/charts?cluster=asia-northeast1-1.cognitedata.com';

export const roadManagementUrl = `${env.uploadOrigin}/sdf-road-management/wwwroot/`;
