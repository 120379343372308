import React, { useState } from 'react';

import { Button, Col, Row } from 'antd';
import { SorterResult } from 'antd/lib/table';

import AlertEventTable from './parts/AlertEventTable';
import OMAlertEvent from '../../../utils/Event/OMAlertEvent';

import './CurrentAlertList.css';

interface CurrentAlertListProps {
  allAlertEvents: OMAlertEvent[];
}

/**
 * 警報一覧画面のコンポーネント
 * @param {CurrentAlertListProps} props プロパティ
 * @returns 警報一覧画面
 */
const CurrentAlertList: React.FC<CurrentAlertListProps> = (props) => {
  const { allAlertEvents } = props;

  // 一覧画面でのソート状態保持用のstate変数（ソートの制御用にAlertEventTableに渡す）
  const [sortInfo, setSortInfo] = useState<SorterResult<OMAlertEvent> | null>(null);

  /*
   * イベントハンドラ
   */

  /**
   * 「ソート解除」ボタンをクリックした時のイベントハンドラ
   * @param {React.MouseEvent} e ボタンのクリックイベント
   */
  const handleClickSortReset = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    setSortInfo(null);
  };

  // 発生中の警報一覧を取得
  const currentAlertEvents = allAlertEvents.filter(({ happening }) => happening);

  return (
    <div>
      <Row type="flex" justify="end" style={{ paddingBottom: '1%' }}>
        <Col span={6} className="om-dashboard-current-button-col">
          <Button className="om-dashboard-alert-list-button" key="reset_sort" title="ソート解除" onClick={handleClickSortReset}>ソート解除</Button>
        </Col>
      </Row>
      <AlertEventTable alertEvents={currentAlertEvents} sortInfo={sortInfo} handleSortChange={setSortInfo} />
    </div>
  );
};

export default CurrentAlertList;
