import React, { useEffect, useState } from 'react';
import { Button, Layout, Tabs } from 'antd';
import { AssetListScope } from '@cognite/sdk/dist/src';

import AlertHistoryList from './AlertHistoryList';
import CurrentAlertList from './CurrentAlertList';
import FacilityMonitor from './FacilityMonitor';
import MeasurementList from './MeasurementList';
import PcsMeasurementEventList from './PcsMeasurementEventList';
import CommonHeaderDisplay from './parts/CommonHeaderDisplay';
import LayoutModal from './parts/LayoutModal';
import OMEquipmentTree from './parts/OMEquipmentTree';
import OMEquipment from '../../../utils/Asset/OMEquipment';
import { loadAllOMEquipmentFromCDFByScope } from '../../../utils/Asset/OMEquipmentAsset';
import OMAlertEvent from '../../../utils/Event/OMAlertEvent';
import { getAreaAssetId } from '../../../utils/storageCommon';
import OMMeasurement from '../../../utils/Timeseries/OMMeasurement/OMMeasurement';

import './OperationAndMaintenance.css';

const { Content } = Layout;
const { TabPane } = Tabs;

export interface DisplayInfo {
  name: string;
  key: string;
}

const allDisplayList: DisplayInfo[] = [
  {
    name: '特高監視画面',
    key: '1',
  },
  {
    name: 'PCS一覧画面',
    key: '2',
  },
  {
    name: '計測一覧画面',
    key: '3',
  },
  {
    name: '警報一覧画面',
    key: '4',
  },
  {
    name: '警報履歴画面',
    key: '5',
  },
];

// 初期表示画面と切り替え先画面の一覧
const defaultDisplay = allDisplayList[0];
const defaultDisplayList = allDisplayList.filter(({ name }) => name !== defaultDisplay.name);

/**
 * O&M特高監視コンポーネント
 * @returns O&M特高監視画面
 */
const OperationAndMaintenance: React.FC = () => {
  const areaAssetId = getAreaAssetId();
  const [activeDisplay, setActiveDisplay] = useState<DisplayInfo>(allDisplayList[0]);
  const [displayList, setDisplayList] = useState<DisplayInfo[]>(defaultDisplayList);
  const [selectedAsset, setSelectedAsset] = useState<OMEquipment | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [allAlertEventList, setAllAlertEventList] = useState<OMAlertEvent[]>([]);
  const [allLatestMeasurementList, setAllLatestMeasurementList] = useState<OMMeasurement[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  /*
   * イベントハンドラ
   */
  useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      if (!areaAssetId) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        const allAlertEvents = await OMAlertEvent.loadAllLatestAlertEvents(areaAssetId);

        // タイムシリーズ保持アセット一覧を取得
        const scope: AssetListScope = {
          filter: {
            assetSubtreeIds: [{ id: areaAssetId }],
            metadata: { o_m_asset_level: 'ts_holder' },
          },
        };
        const timeseriesHolderAssets = await loadAllOMEquipmentFromCDFByScope(scope);
        const targetAssetIds = timeseriesHolderAssets.map(({ id }) => id);
        const latestMeasurements = await OMMeasurement.loadAllSelectedLatestMeasurements(targetAssetIds);

        if (!canceled) {
          setAllAlertEventList(allAlertEvents);
          setAllLatestMeasurementList(latestMeasurements);
          setLoading(false);
        }
      })();
      return () => { canceled = true; };
    },
    [areaAssetId, loading],
  );

  /** 表示画面切り替え時の処理
  * @param {string} selectedKey
  */
  const handleTabChange = (selectedKey: string) => {
    const selectedDisplay = allDisplayList.find(({ key }) => key === selectedKey) || allDisplayList[0];
    const nextDisplayList = allDisplayList.filter(({ name }) => name !== selectedDisplay.name);
    setActiveDisplay(selectedDisplay);
    setDisplayList(nextDisplayList);
  };

  /**
   * PCS一覧のリンクから警報一覧への切り替え用ハンドラ
   */
  const handleDisplayAlertList = () => {
    handleTabChange('4');
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout style={{ height: '100vh' }}>
      {/* sider */}
      <div className="facility-tree">
        <OMEquipmentTree
          parentAssetsId={areaAssetId}
          title=""
          onSelect={(selected) => setSelectedAsset(selected)}
          collapsed
          isExpandedRoot
        />
      </div>
      <Layout>
        <div className="header">
          <CommonHeaderDisplay
            areaAssetId={areaAssetId}
            displayList={displayList}
            activeDisplay={activeDisplay}
            handleButtonClick={handleTabChange}
            allAlertEvents={allAlertEventList}
          />
        </div>

        <Content className="main" style={{ padding: '0 50px' }}>
          <Button className="om-dashboard-layout-display-button" type="primary" onClick={handleOpenModal}>
            レイアウト表示
          </Button>
          <LayoutModal
            areaAssetId={areaAssetId}
            visible={isModalVisible}
            onClose={handleCloseModal}
          />
          <Tabs
            className="om-dashboard-tab-items"
            animated={false}
            activeKey={activeDisplay.key}
            onChange={handleTabChange}
          >
            {/* TabPaneにtabプロパティ（タブの表示名）を明記しないと画面遷移時にコンソールに警告が表示されるので、空文字で指定（タブそのものは表示しないため） */}
            <TabPane tab="" key="1">
              <FacilityMonitor
                allAlertEvents={allAlertEventList}
                allLatestMeasurements={allLatestMeasurementList}
                targetAsset={selectedAsset}
              />
            </TabPane>
            <TabPane tab="" key="2">
              {/* PCS一覧のリンクから警報一覧への切り替え用ハンドラ */}
              <PcsMeasurementEventList areaAssetId={areaAssetId} handleDisplayAlert={handleDisplayAlertList} />
            </TabPane>
            <TabPane tab="" key="3">
              <MeasurementList areaAssetId={areaAssetId} />
            </TabPane>
            <TabPane tab="" key="4">
              <CurrentAlertList allAlertEvents={allAlertEventList} />
            </TabPane>
            <TabPane tab="" key="5">
              <AlertHistoryList areaAssetId={areaAssetId} />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Layout>
  );
};

export default OperationAndMaintenance;
