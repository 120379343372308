import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Table,
  Tooltip,
} from 'antd';
import { ColumnProps, SorterResult } from 'antd/lib/table';

import OMPcsMeasurementEvent from '../../../utils/Event/OMPcsMeasurementEvent';

import './PcsMeasurementEventList.css';

interface PcsMeasurementListProps {
  areaAssetId: number;
  handleDisplayAlert: () => void;
}

/**
 * ソートして返却
 * @param {string} a
 * @param {string} b
 * @returns ソートの判定結果
 */
const getSortedItems = (a: string, b: string) => {
  const first = [a, b].sort()[0];
  return first === a ? -1 : 1;
};

/**
 * ソートして返却(数値)
 * @param {number} a
 * @param {number} b
 * @returns ソートの判定結果
 */
const getSortedNumberItems = (a: number, b: number) => {
  // 値が空の場合は昇順の最上部に配置
  if (!a) {
    return -1;
  }
  if (!b) {
    return 1;
  }
  return a - b;
};

/**
 * PCS一覧画面のコンポーネント
 * @param {PcsMeasurementListProps} props プロパティ
 * @returns PCS一覧画面
 */
const PcsMeasurementEventList: React.FC<PcsMeasurementListProps> = (props) => {
  const { areaAssetId, handleDisplayAlert } = props;

  const [pcsMeasurementList, setPcsMeasurementList] = useState<OMPcsMeasurementEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // 一覧画面でのソート状態保持用のstate変数
  const [sortInfo, setSortInfo] = useState<SorterResult<OMPcsMeasurementEvent> | null>(null);

  // Tableの内容
  const columns: ColumnProps<OMPcsMeasurementEvent>[] = [
    {
      title: 'フィーダNo',
      dataIndex: 'feederNumber',
      width: 140,
      sorter: (a, b) => getSortedNumberItems(a.feeder, b.feeder),
      sortOrder: sortInfo?.columnKey === 'feederNumber' && sortInfo.order,
      render: (_, item: OMPcsMeasurementEvent) => {
        const value = item.feeder;
        const uniqueKey = `feederNumber_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: 'PCS',
      dataIndex: 'pcsName',
      width: 140,
      sorter: (a, b) => getSortedNumberItems(a.pcsSortKey, b.pcsSortKey),
      sortOrder: sortInfo?.columnKey === 'pcsName' && sortInfo.order,
      render: (_, item: OMPcsMeasurementEvent) => {
        const value = item.pcsName;
        const uniqueKey = `pcsName_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '発電電力[kW]',
      dataIndex: 'outputPower',
      width: 200,
      sorter: (a, b) => getSortedNumberItems(a.outputPower, b.outputPower),
      sortOrder: sortInfo?.columnKey === 'outputPower' && sortInfo.order,
      render: (_, item: OMPcsMeasurementEvent) => {
        const value = item.outputPower;
        const uniqueKey = `outputPower_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '出力電流[A]',
      dataIndex: 'outputCurrent',
      width: 200,
      sorter: (a, b) => getSortedNumberItems(a.outputCurrent, b.outputCurrent),
      sortOrder: sortInfo?.columnKey === 'outputCurrent' && sortInfo.order,
      render: (_, item: OMPcsMeasurementEvent) => {
        const value = item.outputCurrent;
        const uniqueKey = `outputCurrent_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '直流電圧[V]',
      dataIndex: 'dcVoltage',
      width: 200,
      sorter: (a, b) => getSortedNumberItems(a.dcVoltage, b.dcVoltage),
      sortOrder: sortInfo?.columnKey === 'dcVoltage' && sortInfo.order,
      render: (_, item: OMPcsMeasurementEvent) => {
        const value = item.dcVoltage;
        const uniqueKey = `dcVoltage_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '直流電流[A]',
      dataIndex: 'dcCurrent',
      width: 200,
      sorter: (a, b) => getSortedNumberItems(a.dcCurrent, b.dcCurrent),
      sortOrder: sortInfo?.columnKey === 'dcCurrent' && sortInfo.order,
      render: (_, item: OMPcsMeasurementEvent) => {
        const value = item.dcCurrent;
        const uniqueKey = `dcCurrent_${item.id}`;
        return (
          <Tooltip title={value} key={uniqueKey}>{value}</Tooltip>
        );
      },
    },
    {
      title: '故障情報',
      dataIndex: 'hasAlertState',
      width: 120,
      sorter: (a, b) => getSortedItems(String(a.hasAlert), String(b.hasAlert)),
      sortOrder: sortInfo?.columnKey === 'hasAlertState' && sortInfo.order,
      render: (_, item: OMPcsMeasurementEvent) => {
        const value = item.hasAlert && '発生';
        const uniqueKey = `hasAlertState_${item.id}`;
        return value && (
          <Tooltip title={value} key={uniqueKey}>
            <Button className="om-dashboard-pcs-measurement-occurrence-button" onClick={handleDisplayAlert}>{value}</Button>
          </Tooltip>
        );
      },
    },
  ];

  /*
   * イベントハンドラ
   */

  /**
   * 「ソート解除」ボタンをクリックした時のイベントハンドラ
   * @param {React.MouseEvent} e ボタンのクリックイベント
   */
  const handleClickSortReset = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.currentTarget.blur();
    setSortInfo(null);
  };

  useEffect(
    () => {
      if (!loading) return () => { /* 何もしない */ };

      if (!areaAssetId) return () => { /* 何もしない */ };

      let canceled = false;
      (async () => {
        const pcsMeasurementEvents = await OMPcsMeasurementEvent.loadAllPcsMeasurementEvents(areaAssetId);

        if (!canceled) {
          setPcsMeasurementList(pcsMeasurementEvents);
          setLoading(false);
        }
      })();

      return () => { canceled = true; };
    },
    [areaAssetId, loading],
  );

  return (
    <div>
      <Row type="flex" justify="end" style={{ paddingBottom: '1%' }}>
        <Col span={3} className="om-dashboard-pcs-button-column">
          <Button
            className="om-dashboard-pcs-measurement-list-button"
            key="reset_sort"
            title="ソート解除"
            onClick={handleClickSortReset}
          >
            ソート解除
          </Button>
        </Col>
      </Row>
      <Table<OMPcsMeasurementEvent>
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={pcsMeasurementList}
        onChange={(_, __, sorter) => setSortInfo(sorter)}
        pagination={false}
        rowClassName={(item) => `${item.feeder}`}
        scroll={{ y: 570 }}
      />
    </div>
  );
};

export default PcsMeasurementEventList;
