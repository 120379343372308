import { CogniteEvent, EventFilter } from '@cognite/sdk';
import moment, { Moment } from 'moment';

import BaseEvent from './BaseEvent';
import { EP_PATH_SOLAR_OM_EVENTS_LIST } from '../AWS/EndpointPath';
import { getAllEvents } from '../dataAccess';

export type SeverityCode = 'A' | 'B' | 'C' | '';

// 画面表示用日付の表示形式
const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

// CSV出力用日付の日時の形式
const CSV_DATE_FORMAT = 'YYYY/MM/DD HH:mm';

class OMAlertEvent extends BaseEvent {
  /**
   * イベント二つを名前順でソート
   * @param {OMAlertEvent} a 比較対象イベント（一つ目）
   * @param {OMAlertEvent} b 比較対象イベント（二つ目）
   * @returns {number} 比較結果
   */
  static sortByMonitorName(a: OMAlertEvent, b: OMAlertEvent): number {
    const nameA = a.monitorName;
    const nameB = b.monitorName;

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  }

  /**
   * イベント二つをソートキーの順でソート
   * @param {OMAlertEvent} a 比較対象イベント（一つ目）
   * @param {OMAlertEvent} b 比較対象イベント（二つ目）
   * @returns {number} 比較結果
   */
  static sortByFacilitySortKey(a: OMAlertEvent, b: OMAlertEvent): number {
    if (a.facilitySortKey < b.facilitySortKey) return -1;
    if (a.facilitySortKey > b.facilitySortKey) return 1;

    return OMAlertEvent.sortByMonitorName(a, b);
  }

  /**
   * メンバ変数
   */
  get areaName(): string {
    if (!this.metadata || !this.metadata?.area) return '';
    return this.metadata.area;
  }

  get siteName(): string {
    if (!this.metadata || !this.metadata?.site) return '';
    return this.metadata.site;
  }

  get floorName(): string {
    if (!this.metadata || !this.metadata?.floor) return '';
    return this.metadata.floor;
  }

  get signalName(): string {
    if (!this.metadata || !this.metadata?.signal_name) return '';
    return this.metadata.signal_name;
  }

  get monitorName(): string {
    if (!this.metadata || !this.metadata?.monitor_name) return this.signalName;
    return this.metadata.monitor_name;
  }

  get severity(): number {
    if (!this.metadata || !this.metadata?.severity) return 0;
    return Number(this.metadata.severity);
  }

  get severityCode(): SeverityCode {
    switch (this.severity) {
      case 1:
        return 'A';
      case 2:
        return 'B';
      case 3:
        return 'C';
      default:
        return '';
    }
  }

  get equipmentType(): string {
    if (!this.metadata || !this.metadata?.equipment_type) return '';
    return this.metadata.equipment_type;
  }

  get happening(): boolean {
    if (!this.metadata
      || !this.metadata?.is_happening
      || !['true', 'false'].includes(this.metadata?.is_happening)
    ) return false;
    return JSON.parse(this.metadata.is_happening);
  }

  get alertState(): string {
    return this.happening ? '発生' : '復旧';
  }

  get facilitySortKey(): number {
    if (!this.metadata || !this.metadata?.facility_sort_key) return 0;
    return Number(this.metadata.facility_sort_key);
  }

  get dateForDisplay(): string {
    if (!this.startTime || !this.startTime) return '';
    return moment(this.startTime).format(DATE_FORMAT);
  }

  get dateForCsv(): string {
    if (!this.startTime || !this.startTime) return '';
    return moment(this.startTime).format(CSV_DATE_FORMAT);
  }

  /**
   * 対象エリアに紐付く、指定した条件に合致する警報履歴情報一覧を取得する。
   * @param areaAssetId 対象エリアのアセットID
   * @returns 対象エリア配下の発生中の全警報情報一覧
   */
  static async loadTargetAlertHistoryEvents(
    areaAssetId: number,
    range?: Moment[],
    severity?: number,
  ): Promise<OMAlertEvent[]> {
    const alertEventFilter: EventFilter = {
      assetSubtreeIds: [
        {
          id: areaAssetId,
        },
      ],
      type: 'alert',
      subtype: 'history',
    };
    if (range) {
      const [min, max] = range.map((date) => date.valueOf());
      alertEventFilter.startTime = { min, max };
    }
    if (severity) {
      alertEventFilter.metadata = { severity: String(severity) };
    }

    const alertEventSort = {
      property: ['startTime'],
      order: 'desc',
    };

    const targetAlertEvents = await getAllEvents(
      EP_PATH_SOLAR_OM_EVENTS_LIST,
      alertEventFilter,
      alertEventSort,
    ) as CogniteEvent[];
    return targetAlertEvents.map((event) => new OMAlertEvent(event));
  }

  /**
   * 対象アセットに紐付く全警報情報一覧を取得する。
   * @param targetAssetId 対象アセットのアセットID
   * @returns 対象アセット配下の全警報情報一覧
   */
  static async loadAllLatestAlertEvents(targetAssetId: number): Promise<OMAlertEvent[]> {
    const allAlertEventFilter: EventFilter = {
      assetSubtreeIds: [
        {
          id: targetAssetId,
        },
      ],
      type: 'alert',
      subtype: 'latest',
    };

    const allAlertEvents = await getAllEvents(
      EP_PATH_SOLAR_OM_EVENTS_LIST,
      allAlertEventFilter,
    ) as CogniteEvent[];

    const allLatestAlertEvents = allAlertEvents.map((event) => new OMAlertEvent(event));
    const sortedAllLatestAlertEvents = [...allLatestAlertEvents].sort(OMAlertEvent.sortByFacilitySortKey);

    return sortedAllLatestAlertEvents;
  }
}

export default OMAlertEvent;
