import React, { Fragment, useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import FacilityList from './parts/FacilityList';
import MeasurementPoint from './parts/MeasurementPoint';
import MeasurementValue from './parts/MeasurementValue';
import OMEquipment from '../../../utils/Asset/OMEquipment';
import OMAlertEvent from '../../../utils/Event/OMAlertEvent';
import OMMeasurement from '../../../utils/Timeseries/OMMeasurement/OMMeasurement';

import './FacilityMonitor.css';

interface FacilityMonitorProps {
  allAlertEvents: OMAlertEvent[];
  allLatestMeasurements: OMMeasurement[];
  targetAsset?: OMEquipment;
}

/**
 * 特高監視画面のコンポーネント
 * @param {FacilityMonitorProps} props プロパティ
 * @returns 特高監視画面
 */
const FacilityMonitor: React.FC<FacilityMonitorProps> = (props: FacilityMonitorProps) => {
  const { allAlertEvents, allLatestMeasurements, targetAsset } = props;

  const [facilityMap, setFacilityMap] = useState<OMAlertEvent[][]>([]);

  /**
   * イベントハンドラ
   */
  useEffect(
    () => {
      if (!targetAsset) return () => { /* 何もしない */ };

      let canceled = false;

      const floorsFacilityMap = Array.from(new Set(allAlertEvents.map(({ floorName }) => floorName)))
        .map((floor) => (
          allAlertEvents.filter(({ assetIds, floorName, severity }) => assetIds?.includes(targetAsset.id) && floorName === floor && severity === 3)
        ));

      if (!canceled) {
        setFacilityMap(floorsFacilityMap);
      }

      return () => { canceled = true; };
    },
    [targetAsset, allAlertEvents],
  );

  /**
   * 対象フロアの計測ポイントのみ取得して返却
   * @param {OMAlertEvent[]} alertEvents 警報イベント一覧
   * @param {OMEquipment} floor 選択中のアセット
   * @returns 対象フロアに属する計測ポイント用イベントリスト
   */
  const filterMeasurementPoints = (alertEvents: OMAlertEvent[], floor?: OMEquipment): OMAlertEvent[] => {
    if (!floor || floor.hasChildFloors) return [];

    return alertEvents.filter(({ assetIds, severity }) => assetIds && assetIds.includes(floor.id) && severity !== 3);
  };

  /**
   * 対象フロアの計測値のみ取得して返却
   * @param {OMMeasurement[]} latestMeasurements 最新の計測値一覧
   * @param {OMEquipment} floor 選択中のアセット
   * @returns 対象フロアに属する計測値リスト
   */
  const filterMeasurementValues = (
    latestMeasurements: OMMeasurement[],
    floor?: OMEquipment,
  ): OMMeasurement[] => {
    if (!floor || floor.hasChildFloors) return [];

    return latestMeasurements.filter(({ floorName }) => floorName === floor.name);
  };

  const measurementPoints = filterMeasurementPoints(allAlertEvents, targetAsset);
  const targetLatestMeasurements = filterMeasurementValues(allLatestMeasurements, targetAsset);

  return (
    <div>
      <Fragment key="facility_monitor">
        {targetAsset && (
          <div>
            {targetAsset.assetLevel === 'floor' && !targetAsset.hasChildFloors && (
              <div style={{ marginTop: '3.3%' }}>
                <MeasurementPoint targetPoints={measurementPoints} />
                <MeasurementValue latestMeasurements={targetLatestMeasurements} />
              </div>
            )}
            {
              (targetAsset.assetLevel === 'floor') && (
                <div style={{ marginTop: `${!targetAsset.hasChildFloors ? 1 : 3.3}%` }}>
                  <div className="om-dashboard-subject-facility-title">対象設備</div>
                  <Row>
                    <Col span={2} className="om-dashboard-facility-floor-name-title">フロア名</Col>
                    <Col span={2} className="om-dashboard-facility-name-list-title">設備名</Col>
                  </Row>
                  {facilityMap.map((facilities) => !!facilities.length && <FacilityList key={facilities[0].floorName} targetFacilities={facilities} />)}
                </div>
              )
            }
          </div>
        )}
      </Fragment>
    </div>
  );
};

export default FacilityMonitor;
